import anime from 'animejs';
import { useEffect } from 'react';
import useMedia from '@/hooks/useMedia';
import IconifyIcon from '@components/IconifyIcon';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { technologiesList } from './data';
export default function Technologies() {
  // hooks
  const media = useMedia();
  const animateCard = (card: HTMLElement, delay: number) => {
    anime({
      targets: card,
      // translateY: [anime.random(-50, 50), 0, anime.random(-50, 50)],
      scale: [0.85, 1, 0.85],
      opacity: [0, 1, 0],
      easing: 'easeInSine',
      direction: 'alternate',
      duration: anime.random(2000, 6000),
      // random end delay to avoid unnatural look
      endDelay: anime.random(100, 1000),
      delay: anime.random(0, delay),
      elasticity: 500,
      loop: true
    });
  };
  useEffect(() => {
    // Trigger animations when the component mounts
    technologiesList.forEach((_, index) => {
      const card = document.getElementById(`technology-card-${index}`);
      if (!card) return;
      animateCard(card, index * 100); // Add a delay for staggered animation
    });
  }, []);
  const divider = media.down.md ? 1 : 2;
  const list = technologiesList.filter((_, index) => index % divider === 0);
  return <Box sx={{
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: {
      xs: '1fr',
      md: '30% 1fr'
    },
    gap: {
      xs: 10,
      md: 20
    }
  }} data-sentry-element="Box" data-sentry-component="Technologies" data-sentry-source-file="index.tsx">
      <Box data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Typography variant="h2" gutterBottom data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          What’s Your Stack?
        </Typography>
        <Typography variant="body1" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          Our team has expertise in almost
          every programming language.
        </Typography>
      </Box>

      <Box sx={{
      display: 'grid',
      gridTemplateColumns: {
        xs: 'repeat(auto-fill, minmax(150px, 1fr))',
        md: 'repeat(4, 1fr)'
      },
      gap: 2
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {list.map((service, index) => <Box key={index} id={`technology-card-${index}`} sx={{
        bgcolor: 'background.paper',
        borderRadius: 2,
        p: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
            <Box sx={{
          mb: 2,
          '& svg': {
            fontSize: '2rem',
            maxWidth: 70,
            color: 'primary.light'
          }
        }}>
              <IconifyIcon sx={{
            color: 'white'
          }} icon={service.icon} />
            </Box>
            <Box>
              <Typography align="center" sx={{
            fontWeight: theme => theme.typography.fontWeightMedium,
            fontSize: {
              xs: '0.75rem',
              md: '1rem'
            }
          }}>
                {service.title}
              </Typography>
            </Box>
          </Box>)}
      </Box>
    </Box>;
}