// ** MUI Imports
import { alpha, Theme } from '@mui/material/styles';

// ** Theme Config Imports
import themeConfig from '../themeConfig';
import media from '../utils/media';

const Button = (theme: Theme) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          lineHeight: 1.71,
          letterSpacing: '0.3px',
          padding: `${theme.spacing(1.875, 3)}`,
          textTransform: 'none',
          // [media.max.sm]: {
          //   padding: `${theme.spacing(1.3, 5.5)}`,
          // },
          '&[data-button-rounded]': {
            borderRadius: 100,
            '&.MuiButton-root': {
              paddingLeft: theme.spacing(5),
              paddingRight: theme.spacing(5),
            },
          },
          '&[data-button-gradient]': {
            background: (theme.palette as any).customColors.gradient.horizontal,
            color: theme.palette.common.white,
            transition: 'all 0.3s ease',
            '&:hover': {
              opacity: 0.9,
            },
            '&[disabled]': {
              opacity: 0.6,
            },
          },
        },
        text: {
          padding: `${theme.spacing(1.875, 3.5)}`,
        },
        contained: {
          boxShadow: theme.shadows[3],
          padding: `${theme.spacing(1.875, 3.5)}`,

          // '&.MuiButton-containedPrimary:not([disabled])': {
          //   background: (theme.palette as any).customColors.primaryGradient,
          // },
          '&.MuiButton-containedWarning': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[800] : theme.palette.grey[800]
          },
          // set disableElevation
          '&.MuiButton-disableElevation': {
            boxShadow: 'none'
          },
        },
        // disableElevation
        containedSuccess: {
          color: theme.palette.success.contrastText,
        },
        outlined: {
          padding: `${theme.spacing(1.625, 3.25)}`,
          borderColor: theme.palette.divider
        },
        outlinedPrimary: {
          borderColor: theme.palette.primary.main,
          ...theme.applyStyles('dark', {
            borderColor: theme.palette.primary.light,
            color: theme.palette.primary.light
          }),
        },
        outlinedSecondary: {
          borderColor: theme.palette.divider,
          color: theme.palette.text.primary,
        },
        // containedSecondary: {
        //   backgroundColor: 'rgba(0, 0, 0, 0.04)',
        //   '&:hover': {
        //     backgroundColor: 'rgba(0, 0, 0, 0.11)',
        //   },
        //   ...theme.applyStyles('dark', {
        //     backgroundColor: 'rgba(255, 255, 255, 0.05)',
        //     '&:hover': {
        //       backgroundColor: 'rgba(255, 255, 255, 0.09)',
        //     },
        //   }),
        // },
        sizeMedium: {
          padding: `${theme.spacing(2.3, 3.5)}`,
          [media.max.sm]: {
            fontSize: '0.875rem',
          },
        },
        sizeSmall: {
          padding: `${theme.spacing(1, 2.25)}`,
          borderRadius: theme.shape.borderRadius * .75,
          textTransform: 'none',
          fontWeight: theme.typography.fontWeightMedium,

          [media.max.sm]: {
            fontSize: '0.675rem'
          },
          '&.MuiButton-contained': {
            padding: `${theme.spacing(1, 2.5)}`,
          },
          '&.MuiButton-outlined': {
            padding: `${theme.spacing(0.75, 2.25)}`,
            // [media.max.sm]: {
            //   padding: `${theme.spacing(0.8, 2.5)}`,
            // },
          }
        },
        sizeLarge: {
          minHeight: 60,
          padding: `${theme.spacing(2.125, 5.5)}`,
          [media.max.sm]: {
            minHeight: 45,
            fontSize: '0.875rem'
          },
          '&.MuiButton-contained': {
            padding: `${theme.spacing(2.125, 6.5)}`,
            [media.max.sm]: {
              padding: `${theme.spacing(1.6, 4)}`,
            }
          },
          '&.MuiButton-outlined': {
            padding: `${theme.spacing(1.875, 6.25)}`,
            [media.max.sm]: {
              padding: `${theme.spacing(1.6, 4)}`,
            }
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: themeConfig.disableRipple
      },
      styleOverrides: {
        root: {
          '&.MuiIconButton-root': {
            borderRadius: theme.shape.borderRadius,
          },
        }
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-contained:not([disabled])': {
            color: theme.palette.primary.contrastText
          },
          '&.MuiLoadingButton-loading': {
            paddingLeft: 40,
          },
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          '.MuiButtonGroup-grouped:not(:last-of-type):hover': {
            borderColor: theme.palette.divider
          },
          '.MuiButton-containedSecondary': {
            borderColor: alpha(theme.palette.divider, 0.1)
          }
        },
      },
    }
  }
}

export default Button
