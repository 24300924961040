import NextLink from 'next/link';
import { useRouter } from 'next/router';

// core
import routes from '@/core/routes';
import FolderIcon from '@mui/icons-material/Folder';
import { Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
export default function Menu() {
  // hooks
  const router = useRouter();

  // data
  const menuList = [{
    label: 'Index',
    href: routes.home
  }
  // {
  //     label: 'Logs',
  //     href: routes.admin.logs,
  // },
  // {
  //     label: 'WS',
  //     href: routes.admin.ws,
  // },
  // {
  //     label: 'Mui',
  //     href: routes.admin.mui.typography,
  // }
  ];
  return <Box data-sentry-element="Box" data-sentry-component="Menu" data-sentry-source-file="Menu.tsx">
            <Box sx={{
      p: 5
    }} data-sentry-element="Box" data-sentry-source-file="Menu.tsx">
                <Typography variant="h6" component={NextLink} href={routes.home} data-sentry-element="Typography" data-sentry-source-file="Menu.tsx">
                    Admin
                </Typography>
            </Box>
            <List dense data-sentry-element="List" data-sentry-source-file="Menu.tsx">
                {menuList.map((item, index) => {
        const isActive = router.pathname === item.href;
        return <ListItemButton key={index} dense component={NextLink} href={item.href} selected={isActive}>
                            <ListItemIcon>
                                <FolderIcon />
                            </ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItemButton>;
      })}
            </List>
        </Box>;
}