import NextLink from 'next/link';
import { logger } from '@/utils/logger';
import { Button, IconButton, Link as MuiLink } from '@mui/material';
import type { ButtonProps as MuiButtonProps, IconButtonProps as MuiIconButtonProps, LinkProps as MuiLinkProps } from "@mui/material";
interface ButtonLinkProps extends MuiButtonProps {
  href: string;
  target?: string;
}
export const ButtonLink = (props: ButtonLinkProps) => {
  if (!props.href) {
    logger.error("ButtonLink component: 'href' is required but not provided.", props);
    return null;
  }
  return <Button {...props} sx={{
    ...props.sx
  }} LinkComponent={NextLink} onClick={e => {
    e.stopPropagation();
    props.onClick && props.onClick(e);
  }} data-sentry-element="Button" data-sentry-component="ButtonLink" data-sentry-source-file="index.tsx" />;
};
interface IconButtonLinkProps extends MuiIconButtonProps {
  href: string;
}
export const IconButtonLink = (props: IconButtonLinkProps) => {
  if (!props.href) {
    logger.error("IconButtonLink component: 'href' is required but not provided.", props);
    return null;
  }
  return <IconButton {...props} LinkComponent={NextLink} onClick={e => {
    e.stopPropagation();
    props.onClick && props.onClick(e);
  }} data-sentry-element="IconButton" data-sentry-component="IconButtonLink" data-sentry-source-file="index.tsx" />;
};
interface LinkProps extends MuiLinkProps {
  href: string;
  locale?: string;
}
export const Link = (props: LinkProps) => {
  if (!props.href) {
    logger.error("Link component: 'href' is required but not provided.", props);
    return null;
  }
  return <MuiLink {...props} component={NextLink} onClick={e => {
    e.stopPropagation();
    props.onClick && props.onClick(e);
  }} data-sentry-element="MuiLink" data-sentry-component="Link" data-sentry-source-file="index.tsx" />;
};