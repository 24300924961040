import routes from '@/core/routes';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
export type MenuItem = {
  name: string;
  href: string;
  icon?: React.ReactNode;
};
export const industries: MenuItem[] = [{
  name: 'AI for Business',
  href: routes.services.ai.path
}, {
  name: 'SaaS/PaaS',
  href: routes.services.cloud.path
}, {
  name: 'Real-Estate',
  href: routes.services.realEstate.path
}, {
  name: 'Automotive',
  href: routes.services.automotive.path
}, {
  name: '3D',
  href: routes.services.unrealEngine.path
}];
export const menu: MenuItem[] = [
// {
//     name: 'Lab',
//     href: 'https://lab.unrealos.com',
//     icon: <ScienceOutlinedIcon fontSize='small' />,
// },
{
  name: 'Projects',
  href: routes.projects.index.path,
  icon: <ScienceOutlinedIcon fontSize='small' />
}, {
  name: 'Solutions',
  href: routes.solutions.index.path,
  icon: <TipsAndUpdatesOutlinedIcon fontSize='small' />
}, {
  name: 'About',
  href: routes.pages.about.path,
  icon: <InfoOutlinedIcon fontSize='small' />
}, {
  name: 'Contact',
  href: routes.pages.contact.path,
  icon: <ContactSupportOutlinedIcon fontSize='small' />
}];