import dynamic from 'next/dynamic';
// hooks
import { GoogleAnalytics } from 'nextjs-google-analytics';

// core
import config from '@/core/config';
import useSentry from './useSentry';

// components
const FacebookPixel = dynamic(() => import('./FacebookPixel'), {
  ssr: false
});
export default function Analytics() {
  // hooks
  useSentry();
  return <>
            <GoogleAnalytics trackPageViews gaMeasurementId={config.analytics.gaMeasurementId} data-sentry-element="GoogleAnalytics" data-sentry-source-file="index.tsx" />
            <FacebookPixel data-sentry-element="FacebookPixel" data-sentry-source-file="index.tsx" />
        </>;
}