import { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import AppMenu from '../AppMenu';
import BillingMenu from './snippets/BillingMenu';
import SupportMenu from './snippets/SupportMenu';
import UserMenu from './snippets/UserMenu';
interface Props {
  children: React.ReactNode;
}
const Container = memo(({
  children
}: Props) => {
  // computed
  const memorizedChildren = useMemo(() => children, [children]);
  return <Box sx={{
    width: '100%'
  }}>
			<Box sx={{
      position: 'sticky',
      zIndex: theme => theme.zIndex.appBar,
      top: 0,
      height: theme => theme.mixins.appBarHeight,
      bgcolor: 'background.paper',
      borderBottom: 1,
      borderColor: 'divider',
      display: 'flex',
      alignItems: 'center'
    }}>

				<Box sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center'
      }}>
					<Box px={1}>
						<AppMenu />
					</Box>
				</Box>
				<Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        pr: 2
      }}>
					<SupportMenu />
					<BillingMenu />
					<UserMenu />
				</Box>
			</Box>
			<Box component="main" sx={{
      // flexGrow: 1,
    }}>
				{memorizedChildren}
			</Box>
		</Box>;
});
export default Container;