import { memo, useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';

// types
import { AlertOptions } from './types';
import UniversalDialog from './UniversalDialog';
const initialState: AlertOptions = {
  title: '',
  message: '',
  confirmButton: {
    label: 'Close',
    color: 'primary'
  },
  settings: {
    maxWidth: 'xs'
  }
};
const AlertDialog = () => {
  // states
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<AlertOptions>(initialState);
  const [resolvePromise, setResolvePromise] = useState<((value: void) => void) | null>(null);

  // Handle opening the dialog with dynamic options
  const handleOpen = useCallback((options: AlertOptions) => {
    return new Promise<void>(resolve => {
      const newOptions = {
        ...initialState,
        ...options
      };
      setOptions(newOptions);
      setResolvePromise(() => resolve);
      setOpen(true);
    });
  }, []);

  // Handle closing the dialog
  const handleClose = useCallback(() => {
    if (resolvePromise) {
      resolvePromise(); // Resolve without returning anything (just acknowledge)
    }
    setOptions(initialState);
    setOpen(false);
  }, [resolvePromise]);

  // Attach the alert function to window.common
  useEffect(() => {
    if (!window.common) {
      window.common = {} as CommonInterface;
    }
    window.common.alert = (options: AlertOptions) => {
      return handleOpen(options);
    };
  }, [handleOpen]);

  // computed
  const message = typeof options.message === 'string' ? options.message.trim() : options.message;
  return <UniversalDialog open={open} title={options.title} onClose={handleClose} maxWidth={options.settings?.maxWidth || 'xs'} dialogActions={[{
    children: options.confirmButton?.label || 'Close',
    onClick: () => {
      handleClose();
      options.confirmButton?.onClick?.();
    },
    color: options.confirmButton?.color || 'primary',
    variant: 'contained',
    disableElevation: true,
    autoFocus: true
  }]} data-sentry-element="UniversalDialog" data-sentry-component="AlertDialog" data-sentry-source-file="AlertDialog.tsx">
            <Typography variant="body1" sx={{
      whiteSpace: 'pre-line'
    }} data-sentry-element="Typography" data-sentry-source-file="AlertDialog.tsx">
                {message}
            </Typography>
        </UniversalDialog>;
};
export default memo(AlertDialog);