// ** MUI Imports
import { Theme } from '@mui/material/styles';

// ** Theme Config Imports

const toggleButton = (theme: Theme) => {
  return {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
        }
      }
    }
  }
}

export default toggleButton
