import NextLink from 'next/link';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { industries, menu, MenuItem } from './data';
const DrawerMenu = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => {
    if ('key' in event && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };
  const renderList = (items: MenuItem[]) => {
    return <List sx={{
      '& .MuiTypography-root': {
        typography: 'subtitle1'
      }
    }} data-sentry-element="List" data-sentry-component="renderList" data-sentry-source-file="DrawerMenu.tsx">
                {items.map((item, index) => <ListItemButton key={index} LinkComponent={NextLink} href={item.href}>
                        {item.icon && <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>}
                        <ListItemText primary={item.name} />
                    </ListItemButton>)}
            </List>;
  };
  const DrawerContent = <Box sx={{
    width: 250
  }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            {renderList(industries)}
            <Divider />
            {renderList(menu)}
        </Box>;
  return <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)} data-sentry-element="IconButton" data-sentry-source-file="DrawerMenu.tsx">
                <MenuIcon data-sentry-element="MenuIcon" data-sentry-source-file="DrawerMenu.tsx" />
            </IconButton>

            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} data-sentry-element="Drawer" data-sentry-source-file="DrawerMenu.tsx">
                {DrawerContent}
            </Drawer>
        </>;
};
export default DrawerMenu;