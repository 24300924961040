import { memo, useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';

// types
import { ConfirmOptions } from './types';
import UniversalDialog from './UniversalDialog';
const initialState: ConfirmOptions = {
  title: '',
  message: '',
  confirmButton: {
    label: 'Confirm',
    color: 'primary'
  },
  settings: {
    maxWidth: 'xs'
  }
};
const ConfirmDialog = () => {
  // states
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ConfirmOptions>(initialState);
  const [resolvePromise, setResolvePromise] = useState<((value: boolean) => void) | null>(null);

  // Handle opening the dialog with dynamic options
  const handleOpen = useCallback((options: ConfirmOptions) => {
    return new Promise<boolean>(resolve => {
      const newOptions = {
        ...initialState,
        ...options
      };
      setOptions(newOptions);
      setResolvePromise(() => resolve);
      setOpen(true);
    });
  }, []);

  // Handle closing the dialog and passing "false" for cancellation
  const handleClose = useCallback(() => {
    if (resolvePromise) {
      resolvePromise(false); // Resolve with false on cancel
    }
    setOptions(initialState);
    setOpen(false);
  }, [resolvePromise]);

  // Handle confirm button click and pass "true"
  const handleConfirm = useCallback(() => {
    if (resolvePromise) {
      resolvePromise(true); // Resolve with true on confirm
    }
    setOpen(false); // Close the dialog
  }, [resolvePromise]);

  // Attach the confirm function to window.common
  useEffect(() => {
    if (!window.common) {
      window.common = {} as CommonInterface;
    }
    window.common.confirm = (options: ConfirmOptions) => {
      return handleOpen(options);
    };
  }, [handleOpen]);
  const renderMessage = () => {
    if (!options.message) return null;
    if (typeof options.message === 'string') {
      return <Typography variant="body1" sx={{
        whiteSpace: 'pre-line'
      }}>
                    {options.message.trim()}
                </Typography>;
    }
    return options.message;
  };
  return <UniversalDialog open={open} title={options.title} onClose={handleClose} maxWidth={options.settings?.maxWidth || 'xs'} dialogActions={[{
    children: 'Cancel',
    onClick: handleClose,
    color: 'inherit',
    variant: 'outlined',
    disableElevation: true
  }, {
    children: options.confirmButton?.label,
    onClick: handleConfirm,
    color: options.confirmButton?.color,
    variant: 'contained',
    disableElevation: true,
    focusRipple: true,
    autoFocus: true
  }]} data-sentry-element="UniversalDialog" data-sentry-component="ConfirmDialog" data-sentry-source-file="ConfirmDialog.tsx">
            {renderMessage()}

        </UniversalDialog>;
};
export default memo(ConfirmDialog);