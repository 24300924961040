
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'carbon:code',
    slug: 'custom-software-development',
    title: 'Custom Software Development | Tailored Solutions by Unrealos',
    description: "Unrealos specializes in custom software development, creating scalable, secure, and efficient solutions tailored to your business needs. Transform your workflows today!",
    fileName: 'content.mdx',
    Component,
}

export default data;