
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'cil:casino',
    slug: 'igaming-games',
    title: "iGaming Solutions | Innovative Games and Platforms by Unrealos",
    description: "Unrealos specializes in iGaming solutions, creating innovative games and reliable platforms with advanced technology, fairness, and user-centric design.",
    fileName: 'content.mdx',
    Component,
}

export default data;