import { getCustomServerSideProps, GetServerSidePropsContext } from '@/utils/serverSide';
import View from '@/views/main';
export default View;
export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  return getCustomServerSideProps(context, {
    config: {
      layout: {
        name: 'public'
      },
      protected: false
    }
  }, {
    redirectIfLogged: false,
    seoConfig: {
      namespace: 'main',
      title: 'Unrealos: Innovating AI, SaaS, and Custom Tech Solutions for Businesses Worldwide',
      description: 'Unrealos specializes in AI-driven platforms, SaaS/PaaS development, automotive and real estate tech, 3D solutions, and tools for developers. Empowering businesses with cutting-edge technology.'
    }
  });
};