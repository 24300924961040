import AlertDialog from '@/components/MuiDialogs/AlertDialog';
import ConfirmDialog from '@/components/MuiDialogs/ConfirmDialog';
import PromptDialog from '@/components/MuiDialogs/PromptDialog';
import Snackbar from '@/components/MuiDialogs/Snackbar';
import PageProgress from '@/components/PageProgress';
import SeoData from './SeoData';
const CommonLayout = () => {
  return <>
            <PageProgress data-sentry-element="PageProgress" data-sentry-source-file="index.tsx" />
            <PromptDialog data-sentry-element="PromptDialog" data-sentry-source-file="index.tsx" />
            <AlertDialog data-sentry-element="AlertDialog" data-sentry-source-file="index.tsx" />

            <ConfirmDialog data-sentry-element="ConfirmDialog" data-sentry-source-file="index.tsx" />
            <Snackbar data-sentry-element="Snackbar" data-sentry-source-file="index.tsx" />
            <SeoData data-sentry-element="SeoData" data-sentry-source-file="index.tsx" />
        </>;
};
export default CommonLayout;