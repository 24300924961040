
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'carbon:code',
    slug: 'parser-scraper',
    title: "Web Scraping and Parsing Services | Powerful Data Extraction by Unrealos",
    description: "Unrealos offers custom web scraping and parsing services, including real-time data extraction and large-scale crawling. Empower your business with actionable web data today.",
    fileName: 'content.mdx',
    Component,
}

export default data;