import { setCookie } from 'cookies-next/client';
// hooks
import moment from 'moment';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

// libs
import { languageList } from '@/utils/languages';

// core
// import setLanguage from 'next-translate/setLanguage';


interface TranslationResult {
    t: (key: string, query?: Record<string, any>) => string;
    lang: string;
    changeLanguage: (lang: string) => void;
    locales: typeof languageList;
}

export const useTranslate = (...ns: string[]): TranslationResult => {

    // hooks
    const { t } = useTranslation(...ns);
    const router = useRouter()
    const lang = router.locale || 'en';
    // const locales = router.locales || [];

    // get only the languages that are supported (in router.locales)
    const locales = languageList.filter((lang) => router.locales?.includes(lang.code))


    const changeLanguage = async (newLang: string) => {
        if (lang === newLang) return;

        // await setLanguage(newLang);
        setCookie('user_lang', newLang);
        router.replace(router.asPath, router.asPath, { locale: newLang });

        // Set the moment locale
        moment.locale(newLang);
    }

    return {
        t,
        lang,
        changeLanguage,
        locales,
    };
};
