// ** MUI Imports
import { Theme } from '@mui/material/styles';

const FormControl = (theme: Theme) => {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormGroup-root': {
            fontSize: theme.typography.subtitle2.fontSize,
            gap: 1,
          },
        }
      }
    },
  }
}

export default FormControl
