
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'mage:box-3d-notification',
    slug: 'interactive-3d',
    title: "Interactive 3D Solutions | Hyper-Realistic Digital Engagement by Unrealos",
    description: "Unrealos delivers cutting-edge interactive 3D solutions using Unreal Engine 5, Pixel Streaming, and cloud computing. Explore hyper-realistic environments and innovative digital twin technology.",
    fileName: 'content.mdx',
    Component,
}

export default data;