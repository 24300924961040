// ** MUI Imports
import { alpha, Theme } from '@mui/material/styles';

const Typography = (theme: Theme) => {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          '& code': {
            backgroundColor: alpha(theme.palette.error.main, 0.3),
            padding: theme.spacing(.5, 1),
            borderRadius: theme.shape.borderRadius / 2,
            fontFamily: 'inherit',
            fontSize: 'inherit',
          },
        },
        gutterBottom: {
          marginBottom: theme.spacing(3)
        }
      }
    }
  }
}

export default Typography
