import { useEffect, useState } from 'react';

const isBrowser = typeof window !== 'undefined'; // Check if it's running in the browser

const getViewports = () => {
    if (!isBrowser) {
        return {
            viewport: { width: 0, height: 0 },
            visualViewport: { width: 0, height: 0 },
        };
    }

    return {
        viewport: {
            width: Math.max(
                document.documentElement.clientWidth || 0,
                window.innerWidth || 0
            ),
            height: Math.max(
                document.documentElement.clientHeight || 0,
                window.innerHeight || 0
            )
        },
        visualViewport: {
            width: window.visualViewport?.width,
            height: window.visualViewport?.height,
        }
    };
}

const useVisualViewport = () => {
    const [state, setState] = useState(getViewports); // Initializes state based on current environment

    useEffect(() => {
        if (!isBrowser) return; // Do nothing if not in the browser

        const handleResize = () => setState(getViewports);
        window.visualViewport?.addEventListener('resize', handleResize);
        return () => window.visualViewport?.removeEventListener('resize', handleResize);
    }, []);

    return state;
}

export default useVisualViewport;
