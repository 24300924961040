import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
const AnimatedBox = animated(Box);
const words = ['AI for Business', 'Web Applications', 'Startup MVPs', 'Real-Estate Solutions', 'Automotive Software'];
const VerticalTextScroller: React.FC = () => {
  const [index, setIndex] = useState(0);
  const props = useSpring({
    from: {
      transform: 'translateY(100%)'
    },
    to: {
      transform: 'translateY(0%)'
    },
    reset: true,
    config: {
      tension: 170,
      friction: 26
    }
  });
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % words.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  return <Box data-animate sx={{
    overflow: 'hidden',
    height: '1.4em',
    fontSize: {
      xs: '1.5em',
      md: '4em'
    },
    fontWeight: theme => theme.typography.fontWeightBold,
    display: 'flex'
    // justifyContent: 'center',
  }} data-sentry-element="Box" data-sentry-component="VerticalTextScroller" data-sentry-source-file="VerticalTextScroller.tsx">
            <AnimatedBox style={props} data-sentry-element="AnimatedBox" data-sentry-source-file="VerticalTextScroller.tsx">
                {words.map((word, i) => <Box key={i} sx={{
        display: i === index ? 'block' : 'none'
      }}>
                        {word}
                    </Box>)}
            </AnimatedBox>
        </Box>;
};
export default VerticalTextScroller;