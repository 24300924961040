const version = '1.0.1';
const cacheBuster = (url: string) => `${url}?v=${version}`;

const config = new class {
	app = {
		name: 'Unrealos',
	};

	analytics = {
		gaMeasurementId: 'G-TM6DHFCKF1',
		mixpanelToken: '5d03e27a6ee8406909172ca011eb3bd9',
		facebookPixelId: '',
	};

	theme = {
		mode: 'dark',
	};

	static = {
		favicons: {
			default: '/favicon.png',
			active: cacheBuster('/static/favicons/active.png'),
			icon192: cacheBuster('/static/favicons/android-chrome-192x192.png'),
			icon384: cacheBuster('/static/favicons/android-chrome-384x384.png'),
			icon512: cacheBuster('/static/favicons/icon-512x512.png'),
		},
		logo: {
			svg: cacheBuster('/static/logo.svg'),
		},
		ogImage: cacheBuster('/static/og-image.jpg'),
	};

	assets = {
		countryFlag: (code: string) => {
			if (code == 'en') code = 'gb';
			return {
				svg: `/static/country-flags/svg/${code}.svg`,
				png: `/static/country-flags/png/${code}.png`,
			};
		},
		logotypes: {
			epicGames: cacheBuster('/static/logotypes/epic-games.svg'),
			lv80: cacheBuster('/static/logotypes/lv80.svg'),
			coinTelegraph: cacheBuster('/static/logotypes/coin-telegraph.svg'),
			forbes: cacheBuster('/static/logotypes/forbes.svg'),
			vc: cacheBuster('/static/logotypes/vc.svg'),
		},
		videos: {
			mainVideo: {
				desktop: {
					url: cacheBuster('/static/main-video/desktop.mp4'),
					silent: cacheBuster('/static/main-video/desktop-silent.mp4'),
					preview: cacheBuster('/static/main-video/preview-desktop.jpg'),
				},
				mobile: {
					url: cacheBuster('/static/main-video/mobile.mp4'),
					silent: cacheBuster('/static/main-video/mobile-silent.mp4'),
					preview: cacheBuster('/static/main-video/preview-mobile.jpg'),
				},
			},
		},
		images: {
			forbes: cacheBuster('/static/images/forbes.jpg'),
		}
	};

	github = {
		getStatic: (path: string) => `https://raw.githubusercontent.com/markolofsen/unrealos4-static/refs/heads/main/${path}`,
		editContent: (path: string) => `https://github.com/markolofsen/unrealos4/edit/main/${path}`,
	};

	links = {
		roadmap: 'https://aieditlab.featurebase.app/roadmap',
		feedback: 'https://aieditlab.featurebase.app',
		community: 'https://groups.google.com/g/aivideos',
		facebook: 'https://www.facebook.com/aieditlab',
		youtube: 'https://www.youtube.com/@aieditlab-com',
		instagram: 'https://www.instagram.com/aieditlab/',
		telegram: 'https://t.me/aieditlab',

		forbesYoutube: 'https://www.youtube.com/watch?v=5bnQpaYiQXc&t=1389s',
	};

	// support = {
	//     whatsapp: 'https://wa.me/message/67C3BJ5Z3FWBP1',
	// }

	// firebase = {
	//     config: {
	//         apiKey: "AIzaSyDDNGTfEnoGc4gWH-UHhgq9qf8D0LdAntk",
	//         authDomain: "ainotes-6cba1.firebaseapp.com",
	//         projectId: "ainotes-6cba1",
	//         storageBucket: "ainotes-6cba1.appspot.com",
	//         messagingSenderId: "698723122534",
	//         appId: "1:698723122534:web:32df997cae4ffbf0b8dca7",
	//     }
	// }
}

export default config;
