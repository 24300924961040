// ** MUI Imports
import { Theme } from '@mui/material/styles';

const CircularProgress = (theme: Theme) => {
    return {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.secondary,
                },
            }
        }
    }
}

export default CircularProgress
