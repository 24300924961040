
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'file-icons:test-generic',
    slug: 'qa-testing',
    title: "QA and Testing Services | Bulletproof Your Technology with Unrealos",
    description: "Unrealos offers comprehensive QA and testing services, including manual, automated, performance, and security testing, to ensure your technology is reliable, secure, and user-friendly.",
    fileName: 'content.mdx',
    Component,
}

export default data;