import React, { useState } from 'react';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Popover, Tooltip, Typography } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

// types
export type ToolbarMenuItem = {
  label: string;
  icon?: React.ElementType;
  onClick?: () => void;
  disabled?: boolean;
  selected?: boolean;
};
type ToolbarMenuProps = {
  icon?: React.ElementType;
  children?: React.ReactNode;
  menuItems: ToolbarMenuItem[];
  position?: 'right' | 'left';
  tooltipLabel?: string;
  disabled?: boolean;
  sx?: IconButtonProps['sx'];
  size?: IconButtonProps['size'];
};
export default function ToolbarMenu({
  icon: CustomIcon = MoreHorizOutlinedIcon,
  children,
  menuItems,
  position = 'right',
  tooltipLabel = 'More',
  // Default label for Tooltip
  disabled,
  ...props
}: ToolbarMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return <div data-sentry-component="ToolbarMenu" data-sentry-source-file="ToolbarMenu.tsx">
            <Tooltip title={tooltipLabel} placement="bottom" arrow disableInteractive data-sentry-element="Tooltip" data-sentry-source-file="ToolbarMenu.tsx">
                <IconButton sx={{
        ...(props.sx && props.sx)
      }} size={props.size || 'small'} onClick={handleClick} disabled={disabled} data-sentry-element="IconButton" data-sentry-source-file="ToolbarMenu.tsx">
                    <CustomIcon fontSize="inherit" data-sentry-element="CustomIcon" data-sentry-source-file="ToolbarMenu.tsx" />
                </IconButton>
            </Tooltip>
            <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
      vertical: 'bottom',
      horizontal: position === 'right' ? 'right' : 'left'
    }} transformOrigin={{
      vertical: 'top',
      horizontal: position === 'right' ? 'right' : 'left'
    }} slotProps={{
      paper: {
        sx: {
          width: 240
        }
      }
    }} data-sentry-element="Popover" data-sentry-source-file="ToolbarMenu.tsx">
                <Box data-sentry-element="Box" data-sentry-source-file="ToolbarMenu.tsx">
                    {children && <Box sx={{
          borderBottom: 1,
          borderColor: 'divider',
          p: 5
        }}>
                            {children}
                        </Box>}
                    <List dense sx={{
          '& .MuiListItemText-root': {
            my: 1
          },
          '& .MuiSvgIcon-root': {
            fontSize: 20
          }
        }} data-sentry-element="List" data-sentry-source-file="ToolbarMenu.tsx">
                        {menuItems.map((item, index) => <ListItemButton key={index} selected={item.selected} disabled={item.disabled} onClick={() => {
            item.onClick?.();
            handleClose();
          }}>
                                {item.icon && <ListItemIcon>
                                        <item.icon fontSize="small" sx={{
                color: 'text.secondary'
              }} />
                                    </ListItemIcon>}
                                <ListItemText primary={<Typography variant="subtitle2">{item.label}</Typography>} />
                            </ListItemButton>)}
                    </List>
                </Box>
            </Popover>
        </div>;
}