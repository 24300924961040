import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect } from 'react';

import config from '@/core/config';
import env from '@/core/env';

const MIXPANEL_TOKEN = config.analytics.mixpanelToken;

// Initialize Mixpanel only in production
if (env.isProd) {
    mixpanel.init(MIXPANEL_TOKEN, {
        debug: false,
        secure_cookie: true,
        ignore_dnt: true,
    });
}

interface MixpanelProps {
    [key: string]: any;
}

const useMixpanel = () => {
    const isProd = env.isProd;

    useEffect(() => {
        if (isProd) {
            // Automatically track page views in production
            mixpanel.track_pageview();
        }
    }, [isProd]);

    const trackEvent = useCallback((eventName: string, properties?: MixpanelProps) => {
        if (isProd) {
            mixpanel.track(eventName, properties);
        }
    }, [isProd]);

    const trackPageView = useCallback((properties?: MixpanelProps) => {
        if (isProd) {
            mixpanel.track_pageview(properties);
        }
    }, [isProd]);

    const identifyUser = useCallback((userId: string | number) => {
        if (isProd) {
            mixpanel.identify(userId?.toString());
        }
    }, [isProd]);

    const setUserProperties = useCallback((properties: MixpanelProps) => {
        if (isProd) {
            mixpanel.people.set(properties);
        }
    }, [isProd]);

    const setSuperProperties = useCallback((properties: MixpanelProps) => {
        if (isProd) {
            mixpanel.register(properties);
        }
    }, [isProd]);

    const setSuperPropertiesOnce = useCallback((properties: MixpanelProps) => {
        if (isProd) {
            mixpanel.register_once(properties);
        }
    }, [isProd]);

    const trackLinkClicks = useCallback((selector: string, eventName: string, properties?: MixpanelProps) => {
        if (isProd) {
            mixpanel.track_links(selector, eventName, properties);
        }
    }, [isProd]);

    const trackFormSubmissions = useCallback((selector: string, eventName: string, properties?: MixpanelProps) => {
        if (isProd) {
            mixpanel.track_forms(selector, eventName, properties);
        }
    }, [isProd]);

    return {
        trackEvent,
        trackPageView,
        identifyUser,
        setUserProperties,
        setSuperProperties,
        setSuperPropertiesOnce,
        trackLinkClicks,
        trackFormSubmissions,
    };
};

export default useMixpanel;
