import { useState } from 'react';

type CopyStatus = 'idle' | 'success' | 'error';

export const useCopy = () => {
  const [status, setStatus] = useState<CopyStatus>('idle');
  const copied = status === 'success';

  const copy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setStatus('success');
      setTimeout(() => setStatus('idle'), 2000); // Reset status after 2 seconds
    } catch (err) {
      setStatus('error');
      setTimeout(() => setStatus('idle'), 2000); // Reset status after 2 seconds
    }
  };

  return {
    status,
    copied,
    copy,
  };
};
