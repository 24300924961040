
/**
 * i18n.js
 * 
 * Documentation:
 * https://github.com/aralroca/next-translate
 */

const axios = require('axios');

// setting up namespaces
const commonNamespaces = ["common", "components"];


module.exports = {
    loader: false,
    // locales: ["en", "es", "fr", "de", "pt", "it", "ru"],
    locales: ["en", "es", "ru"],
    defaultLocale: "en",
    pages: {
        "*": commonNamespaces,
        // "/": ["common", "home", "example"],
        // "/test": ["common"],
        "/": ["main"],
        // "/my/onboarding": ["onboarding"],
    },

    // namespaces for storybook
    namespaces: (lang) => {
        const allNamespaces = [...commonNamespaces, "main"];
        return allNamespaces.reduce((acc, ns) => {
            acc[ns] = require(`./public/locales/${lang}/${ns}.json`);
            return acc;
        }, {})
    },

    loadLocaleFrom: (lang, ns) => {
        // You can use a dynamic import, fetch, whatever. You should
        // return a Promise with the JSON file.
        return import(`./public/locales/${lang}/${ns}.json`).then((m) => m.default)
    },
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
        formatSeparator: ',',
        escapeValue: false, // not needed for react as it escapes by default
        format: (value, format, lang) => {
            if (format === 'number') {
                return new Intl.NumberFormat(lang).format(value);
            }
            return value;
        },
    },
    defaultNS: 'common',
    logBuild: true,
    logger: async (msg) => {
        const { namespace, i18nKey } = msg;

        console.log(`Translation key "${i18nKey}" not found in namespace "${namespace}"`);

        const url = `${process.env.FRONTEND_URL}/api/log-missing-translation/`
        await axios.post(url, { namespace, i18nKey });
    },
    loggerEnvironment: 'both'
}