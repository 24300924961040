// ** MUI Imports
import { Theme } from '@mui/material/styles';

// ** Theme Config Imports

const paper = (theme: Theme) => {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        },
        rounded: {
          borderRadius: 16
        }
      }
    }
  }
}

export default paper