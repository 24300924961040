
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'mdi:casino-chip',
    slug: 'igaming-casinos',
    title: "iGaming Casino Development | Professional Online Platforms by Unrealos",
    description: "Unrealos specializes in creating professional online casino platforms with cutting-edge features, fairness, and security. Elevate your iGaming experience with us.",
    fileName: 'content.mdx',
    Component,
}

export default data;