
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'carbon:application-mobile',
    slug: 'mobile-app-development',
    title: "Mobile App Development | Scalable and Secure Solutions by Unrealos",
    description: "Unrealos specializes in building high-performance, scalable, and secure mobile apps for iOS and Android. Focus on user-centric design, integration, and business growth.",
    fileName: 'content.mdx',
    Component,
}

export default data;