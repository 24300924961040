import env from '@/core/env';
import { loadStripe, Stripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;

const getStripe = (): Promise<Stripe | null> => {
    if (!stripePromise) {
        stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY);
    }
    return stripePromise;
};

export default getStripe;
