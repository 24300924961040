// ** MUI Imports
import { Theme } from '@mui/material/styles';

const Link = (theme: Theme) => {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
        }
      }
    }
  }
}

export default Link