// ** MUI Imports
import { Theme } from '@mui/material/styles';

const Badge = (theme: Theme) => {
  return {
    MuiBadge: {
      styleOverrides: {
        root: {
        },
      },
    }
  }
}

export default Badge
