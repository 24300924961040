import anime from 'animejs';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';

// @data
import { Link } from '@/components/Link';
import routes from '@/core/routes';
import data from '@/views/solutions/data';
import IconifyIcon from '@components/IconifyIcon';
import { Box, Button, Typography } from '@mui/material';
export default function Services() {
  // states
  const [open, setOpen] = useState(false);
  const animateCards = () => {
    anime({
      targets: '.service-card',
      translateY: [-100, 0],
      opacity: [0, 1],
      delay: anime.stagger(100),
      easing: 'easeOutQuad'
    });
  };
  const items = open ? data : data.slice(0, 6);
  return <InView onChange={(visible: boolean) => {
    if (visible) {
      animateCards();
    }
  }} data-sentry-element="InView" data-sentry-component="Services" data-sentry-source-file="Services.tsx">
      <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 15
    }} data-sentry-element="Box" data-sentry-source-file="Services.tsx">
        <Typography variant="h2" align="center" data-sentry-element="Typography" data-sentry-source-file="Services.tsx">
          We offer a wide range of services
        </Typography>
        <Box sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)'
        },
        gap: {
          xs: 10,
          lg: 20
        }
      }} data-sentry-element="Box" data-sentry-source-file="Services.tsx">
          {items.map((item, index) => {
          return <Box key={index} className="service-card">
                <Box sx={{
              mb: 2,
              '& svg': {
                fontSize: '3rem',
                color: 'primary.light'
              }
            }}>
                  {item.icon && <IconifyIcon icon={item.icon} />}
                </Box>
                <Box>
                  <Link href={routes.solutions.detail.as({
                slug: item.slug
              })} variant="h5" color="inherit" sx={{
                mb: 2,
                whiteSpace: 'pre-line'
              }}>
                    {item.title}
                  </Link>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                </Box>
              </Box>;
        })}
        </Box>

        <Box sx={{
        display: 'flex',
        justifyContent: 'center'
      }} data-sentry-element="Box" data-sentry-source-file="Services.tsx">
          <Button variant="outlined" onClick={() => setOpen(!open)} data-sentry-element="Button" data-sentry-source-file="Services.tsx">
            {open ? 'Show less' : 'Show more'}
          </Button>
        </Box>
      </Box>

    </InView>;
}