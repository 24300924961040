// ** MUI Imports
import { Theme } from '@mui/material/styles';

const tree = (theme: Theme) => {
  return {
    MuiTreeItem: {
      styleOverrides: {
        root: {
          userSelect: 'none',
          borderRadius: theme.shape.borderRadius / 2,
        },
        content: {
          borderRadius: theme.shape.borderRadius / 2,

          [`&.Mui-selected`]: {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.selected,
            },
          },
          [`&.Mui-focused`]: {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.selected,
            },
          },
        },
        label: {
          color: theme.palette.text.primary,
          fontSize: theme.typography.subtitle2.fontSize,
        },
      },
    },
  };
};

export default tree;
