// ** MUI Imports
import { Theme } from '@mui/material/styles';

// ** Theme Config Imports
import media from '../utils/media';

const input = (theme: Theme) => {

  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...theme.applyStyles('light', {
            color: theme.palette.text.primary,
          }),
          '&.Mui-focused': {
            ...theme.applyStyles('dark', {
              color: theme.palette.primary.light,
            }),
          },
          '&.Mui-error': {
            color: theme.palette.error.main,
          },
          '&.MuiInputLabel-sizeSmall': {
            fontSize: '0.975rem',
            lineHeight: 1.777,
            [media.max.sm]: {
              fontSize: '0.875rem',
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: `1px solid ${theme.palette.divider}`
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid ${theme.palette.divider}`
          },
          '&.Mui-disabled:before': {
            borderBottomStyle: 'solid'
          },
        },
      }
    },
    MuiInputBase: {
      // defaultProps: {
      //   size: 'medium',
      // },
      styleOverrides: {
        root: {
          [media.max.sm]: {
            fontSize: '0.975rem',
          },
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
            WebkitTextFillColor: theme.palette.text.primary,
            backgroundColor: 'transparent',
          },

          [media.max.sm]: {
            fontSize: '0.975rem',
            '& + .MuiInputAdornment-root .MuiTypography-root': {
              fontSize: '0.875rem',
            },
          }
        },
        inputSizeSmall: {
          fontSize: '0.975rem',
          height: '1.777em',
          minHeight: '1.777em',
          [media.max.sm]: {
            fontSize: '0.875rem',
          },
          // '& + .MuiInputAdornment-root .MuiTypography-root': {
          //   fontSize: '0.975rem',
          // },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
          '&:hover:not(.Mui-disabled)': {
            backgroundColor: theme.palette.background.default,
          },
          '&:before': {
            borderBottom: `1px solid ${theme.palette.divider}`
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid ${theme.palette.divider}`
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          '&:hover:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error)': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.divider,
            },
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              ...theme.applyStyles('dark', {
                borderColor: theme.palette.primary.light,
              }),
              transition: theme.transitions.create(['border-color'], {
                duration: theme.transitions.duration.short,
              }),
              borderWidth: 2,
            }
          },
          '&:hover.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.divider,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.disabled
          },
        },
      }
    },
  }
}

export default input
