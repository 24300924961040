import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import type { Breakpoint } from '@mui/system';

type MediaType = Record<Breakpoint, boolean>;

interface MediaQueryObject {
	up: MediaType;
	down: MediaType;
	only: MediaType;
	mobile: boolean;
	tablet: boolean;
	desktop: boolean;
}

export const useMedia = (): MediaQueryObject => {
	const theme = useTheme<Theme>();
	const breakpoints: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];

	const up: MediaType = {
		xs: useMediaQuery(theme.breakpoints.up('xs')),
		sm: useMediaQuery(theme.breakpoints.up('sm')),
		md: useMediaQuery(theme.breakpoints.up('md')),
		lg: useMediaQuery(theme.breakpoints.up('lg')),
		xl: useMediaQuery(theme.breakpoints.up('xl')),
	};

	const down: MediaType = {
		xs: useMediaQuery(theme.breakpoints.down('xs')),
		sm: useMediaQuery(theme.breakpoints.down('sm')),
		md: useMediaQuery(theme.breakpoints.down('md')),
		lg: useMediaQuery(theme.breakpoints.down('lg')),
		xl: useMediaQuery(theme.breakpoints.down('xl')),
	};

	const only: MediaType = {
		xs: useMediaQuery(theme.breakpoints.only('xs')),
		sm: useMediaQuery(theme.breakpoints.only('sm')),
		md: useMediaQuery(theme.breakpoints.only('md')),
		lg: useMediaQuery(theme.breakpoints.only('lg')),
		xl: useMediaQuery(theme.breakpoints.only('xl')),
	};

	const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
	const desktop = useMediaQuery(theme.breakpoints.up('md'));

	// Use media query to determine support for hover by checking devices that do not lack hover capabilities
	// const isHoverable = useMediaQuery('@media not all and (hover: none)', { noSsr: true });

	return { up, down, only, mobile, tablet, desktop };
};

export default useMedia;
