import api from '../base';

import type { CheckoutRequestBody } from '@/pages/api/stripe/checkout_sessions';
import type { Paths, Response, Schemas } from '../types';


type CheckoutApiResponse = CheckoutRequestBody & { id: string };

export default class BillingApi {

    static stripeCheckoutSession(data: CheckoutRequestBody): Response<CheckoutApiResponse> {
        const url = '/dj/billing/stripe/checkout_sessions/';
        return api.post({ url, data, mocked: true });
    }

    static stripePayment(data: any): Response<Schemas['StripePaymentCreate']> {
        const url: keyof Paths = '/dj/billing/stripe/payment/';
        return api.post({ url, data, mocked: true });
    }
}
