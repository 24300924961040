// ** MUI Imports
import { Theme } from '@mui/material/styles';

// ** Type Import
import { ThemeConfig } from '../themeConfig';
// ** Overrides Imports
import MuiAccordion from './accordion';
import MuiAlerts from './alerts';
import MuiAutocomplete from './autocomplete';
import MuiAvatar from './avatars';
import MuiBackdrop from './backdrop';
import MuiBadge from './badge';
import MuiButton from './button';
import MuiCard from './card';
import MuiChip from './chip';
import MuiCircularProgress from './circularProgress';
import MuiContainer from './container';
import MuiDataGrid from './dataGrid';
import MuiDialog from './dialog';
import MuiDivider from './divider';
import MuiFormControl from './formControl';
import MuiFormControlLabel from './formControlLabel';
import MuiInput from './input';
import MuiLink from './link';
import MuiList from './list';
import MuiMenu from './menu';
import MuiPagination from './pagination';
import MuiPaper from './paper';
import MuiPopover from './popover';
import MuiPopper from './popper';
import MuiRating from './rating';
import MuiSelect from './select';
import MuiSnackbar from './snackbar';
import MuiSwitches from './switches';
import MuiTable from './table';
import MuiTabs from './tabs';
import MuiTimeline from './timeline';
import MuiToggleButton from './toggleButton';
import MuiTooltip from './tooltip';
import MuiTree from './tree';
import MuiTypography from './typography';

const Overrides = (theme: Theme, settings: ThemeConfig) => {
  const { skin } = settings

  const button = MuiButton(theme)
  const chip = MuiChip(theme)
  const badge = MuiBadge(theme)
  const list = MuiList(theme)
  const tabs = MuiTabs(theme)
  const input = MuiInput(theme)
  const tables = MuiTable(theme)
  const alerts = MuiAlerts(theme)
  const rating = MuiRating(theme)
  const avatars = MuiAvatar(theme)
  const divider = MuiDivider(theme)
  const link = MuiLink(theme)
  const menu = MuiMenu(theme, skin)
  const tooltip = MuiTooltip(theme)
  const cards = MuiCard(theme, skin)
  const backdrop = MuiBackdrop(theme)
  const dataGrid = MuiDataGrid(theme)
  const switches = MuiSwitches(theme)
  const timeline = MuiTimeline(theme)
  const accordion = MuiAccordion(theme)
  const dialog = MuiDialog(theme, skin)
  const select = MuiSelect(theme)
  const pagination = MuiPagination(theme)
  const popover = MuiPopover(theme, skin)
  const popper = MuiPopper(theme, skin)
  const snackbar = MuiSnackbar(theme, skin)
  const autocomplete = MuiAutocomplete(theme, skin)
  const container = MuiContainer(theme)
  const formControlLabel = MuiFormControlLabel(theme)
  const circularProgress = MuiCircularProgress(theme)
  const paper = MuiPaper(theme)
  const typography = MuiTypography(theme)
  const formControl = MuiFormControl(theme)
  const toggleButton = MuiToggleButton(theme)
  const tree = MuiTree(theme)

  return Object.assign(
    chip,
    badge,
    list,
    menu,
    tabs,
    cards,
    input,
    alerts,
    button,
    dialog,
    rating,
    tables,
    avatars,
    divider,
    link,
    popover,
    popper,
    tooltip,
    backdrop,
    dataGrid,
    paper,
    snackbar,
    switches,
    timeline,
    accordion,
    container,
    select,
    pagination,
    autocomplete,
    typography,
    formControl,
    tree,
    toggleButton,
    formControlLabel,
    circularProgress,
  )
}

export default Overrides
