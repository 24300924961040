import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { logger } from '@/utils/logger'; // Define the allowed storage types

type StorageType = 'local' | 'session';

// Define the options for each storage type
interface BaseStorageOptions {
    key: string;
    debounceDelay?: number; // Optional debounce delay (default: 300ms)
}

interface LocalStorageOptions extends BaseStorageOptions {
    storageType: 'local';
    version: number; // Required for localStorage
}

interface SessionStorageOptions extends BaseStorageOptions {
    storageType: 'session';
    version?: never; // Disallowed for sessionStorage
}

// Union type for the storage options
type UseStorageOptions = LocalStorageOptions | SessionStorageOptions;

const slugify = (text: string) => {
    return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/[\s\-]+/g, '_')
        .replace(/[^\w_]+/g, '')
        .replace(/__+/g, '_')
        .replace(/^_+|_+$/g, '');
};

export const clearStorage = (storageType: StorageType = 'local') => {
    const storage = storageType === 'local' ? localStorage : sessionStorage;
    Object.keys(storage).forEach((key) => {
        if (key.startsWith('use_')) {
            storage.removeItem(key);
            logger.info(`Removed key "${key}" from ${storageType === 'local' ? 'localStorage' : 'sessionStorage'}`);
        }
    });
};

export const useStorage = <T,>(
    initialValue: T,
    options: UseStorageOptions
): [T, (value: Partial<T>) => void] => {
    const { key, storageType, debounceDelay = 300 } = options;
    const slugifiedKey = `use_${slugify(key)}`;

    const isClient = typeof window !== 'undefined';

    // Determine the storage to use
    let storage: Storage | null = null;
    if (isClient) {
        storage = storageType === 'local' ? window.localStorage : window.sessionStorage;
    }

    const debouncedSaveToStorage = debounce((stateToStore: T | { version: number; state: T }) => {
        if (isClient && storage) {
            try {
                storage.setItem(slugifiedKey, JSON.stringify(stateToStore));
            } catch (error) {
                logger.error(`Failed to save data in ${storageType === 'local' ? 'localStorage' : 'sessionStorage'} for key "${slugifiedKey}":`, error);
            }
        }
    }, debounceDelay);

    // Read from storage
    const readStorage = (): T => {
        if (!isClient || !storage) {
            return initialValue;
        }

        const storedData = storage.getItem(slugifiedKey);
        if (storedData) {
            try {
                const parsedData = JSON.parse(storedData);

                if (storageType === 'local' && parsedData.version === options.version) {
                    return parsedData.state;
                } else if (storageType === 'local') {
                    logger.warn(`Version mismatch: expected ${options.version}, found ${parsedData.version}. Resetting to initial value.`);
                } else {
                    return parsedData;
                }
            } catch (error) {
                logger.error(`Error parsing stored data for key "${slugifiedKey}":`, error);
            }
        }
        return initialValue;
    };

    const [state, setState] = useState<T>(isClient ? readStorage() : initialValue);

    const dispatchContentStorage = (value: Partial<T>) => {
        setState((prev) => ({
            ...prev,
            ...value,
        }));
    };

    useEffect(() => {
        if (!isClient || !storage) return;

        const dataToStore = storageType === 'local'
            ? { version: options.version, state }
            : state;

        debouncedSaveToStorage(dataToStore);

        return () => {
            debouncedSaveToStorage.cancel();
        };
    }, [slugifiedKey, state, storageType, options.version, isClient]);

    return [state, dispatchContentStorage];
};
