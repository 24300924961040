import customIcons from '@/icons/icons-bundle.json'; // Assuming this path is correct for your project
import { addCollection, Icon, IconProps } from '@iconify/react';
import { Box, BoxProps } from '@mui/material';

// Register the custom icons globally
addCollection(customIcons);

// Define the custom icon type
type CustomIcons = keyof typeof customIcons.icons;
interface Props extends IconProps {
  icon: CustomIcons | string; // Icon name (e.g., "custom:circle" or "mdi:home")
  sx?: BoxProps['sx'] & {
    color?: string; // Optional custom color
  };
  isStatic?: boolean; // If true, indicates the icon is from the custom bundle
}
const IconifyIcon = ({
  icon,
  sx,
  isStatic,
  ...rest
}: Props) => {
  // Use the 'custom' prefix if the icon is part of the custom set
  const processedIcon = isStatic ? `custom:${icon}` : icon;
  return <Box component="span" sx={{
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    color: 'text.primary',
    ...sx,
    // Uncomment this line if you want to apply color directly to paths in the icon
    '& path': {
      fill: sx?.color || undefined
    }
  }} data-sentry-element="Box" data-sentry-component="IconifyIcon" data-sentry-source-file="index.tsx">
      <Icon icon={processedIcon} // Use the icon with the custom prefix if necessary
    color={sx?.color} fontSize="inherit" {...rest} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
    </Box>;
};
export default IconifyIcon;