
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'carbon:ibm-cloud-virtual-server-classic',
    slug: 'platform-infrastructure',
    title: "Platform and Infrastructure Development | Secure and Scalable Solutions by Unrealos",
    description: "Unrealos specializes in secure, fault-tolerant, and scalable platform and infrastructure solutions for PaaS, SaaS, and hybrid environments. Build your foundation for digital resilience and high performance.",
    fileName: 'content.mdx',
    Component,
}

export default data;