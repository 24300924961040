import { memo, useEffect } from 'react';

// context
import { useAuthContext } from '@/context';
interface Props {
  children: React.ReactNode;
}
const InitChildren = ({
  children
}: Props) => {
  // context
  const {
    manager,
    state: {
      userData
    }
  } = useAuthContext();

  // context
  // const ws = useWsContext();

  /**
   * Auto initialize auth
   */
  useEffect(() => {
    // Initialize auth hook
    manager.autoInit();
  }, []);

  /**
   * Connect to websocket
   */
  // useEffect(() => {
  //     if (!manager.isLogged) return;
  //     const user_id = userData?.id;
  //     if (user_id) {
  //         ws.connect(user_id);
  //     }

  // }, [manager.isLogged]);

  return <>
            {children}
        </>;
};
export default memo(InitChildren);