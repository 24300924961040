import { useEffect } from 'react';
import config from '@/core/config';
import useMedia from '@/hooks/useMedia';
import useVisualViewport from '@/hooks/useVisualViewport';
import mui from '@/theme/mui';
import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
interface Props {
  children: React.ReactNode;
  deviceType?: string;
}
const DefaultThemeDefault = ({
  children,
  deviceType
}: Props) => {
  // hooks
  const media = useMedia();
  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)');
  const {
    visualViewport
  } = useVisualViewport();

  // computed
  const configMode = config.theme.mode;
  const preferredMode = isDarkModeEnabled ? 'dark' : 'light';
  const mode = configMode === 'auto' ? preferredMode : configMode;
  const theme = mui(mode as PaletteMode, deviceType);

  // Change appbar height on window resize
  useEffect(() => {
    const backgroundDefault = theme.palette.background.default;
    const primaryMain = theme.palette.primary.main;
    const primaryDark = theme.palette.primary.dark;
    document.documentElement.style.setProperty('--background-default', backgroundDefault);
    document.documentElement.style.setProperty('--primary-main', primaryMain);
    document.documentElement.style.setProperty('--primary-dark', primaryDark);
    const handleResize = () => {
      const appbarHeight = media.up.sm ? 56 : 48;
      document.documentElement.style.setProperty('--appbar-height', `${appbarHeight}px`);
      document.documentElement.style.setProperty('--content-height', `calc(var(--document-height, 100vh) - ${appbarHeight}px)`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [media.up.sm]);
  useEffect(() => {
    const height = visualViewport.height || window.innerHeight;
    document.documentElement.style.setProperty('--document-height', `${height}px`);
  }, [visualViewport.height]);
  return <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-component="DefaultThemeDefault" data-sentry-source-file="Default.tsx">
            <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="Default.tsx" />
            {children}
        </ThemeProvider>;
};
export default DefaultThemeDefault;