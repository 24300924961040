import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { memo, useEffect, useState } from 'react';
import { useAuthContext } from '@/context';
import config from '@/core/config';
import routes from '@/core/routes';
import LogoAnimation from '@/snippets/LogoAnimation';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import MenuList from './MenuList';
import type { MenuItem } from './types';
const Sidebar = () => {
  // State to control the drawer
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Context
  const {
    manager: {
      isAdmin
    }
  } = useAuthContext();

  // Hooks
  const router = useRouter();
  useEffect(() => {
    setDrawerOpen(false);
  }, [router.pathname]);

  // Menu items
  const menuItems: MenuItem[] = [{
    icon: <FolderSpecialOutlinedIcon />,
    label: 'Projects',
    href: routes.dashboard.index.path,
    mask: 'dashboard'
  }, {
    icon: <MonetizationOnOutlinedIcon />,
    label: 'Billing',
    href: routes.billing.index.path,
    mask: 'billing'
  }];
  const bottomMenuItems: MenuItem[] = [{
    icon: <SettingsOutlinedIcon />,
    label: 'Settings',
    onClick: () => {
      // commonEvents.settingsDialog.emit();
    },
    mask: 'settings'
  }, {
    icon: <SecurityIcon />,
    label: 'Admin',
    href: routes.admin.index.path,
    mask: 'admin',
    onlyAdmin: true
  }];
  const bottomMenuItemsFiltered = bottomMenuItems.filter(item => !item.onlyAdmin || item.onlyAdmin && isAdmin);

  // Toggle drawer visibility
  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };
  return <>
            {/* Burger Icon */}
            <IconButton onClick={() => toggleDrawer(true)} data-sentry-element="IconButton" data-sentry-source-file="index.tsx">
                <MenuIcon data-sentry-element="MenuIcon" data-sentry-source-file="index.tsx" />
            </IconButton>

            {/* Drawer */}
            <Drawer anchor="left" open={drawerOpen} onClose={() => toggleDrawer(false)} data-sentry-element="Drawer" data-sentry-source-file="index.tsx">
                <Box sx={{
        width: 250
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                    <Box component={NextLink} href={routes.home} sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          px: 3,
          py: 5,
          color: 'inherit',
          textDecoration: 'none',
          borderBottom: 1,
          borderColor: 'divider'
        }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                        <LogoAnimation size={24} data-sentry-element="LogoAnimation" data-sentry-source-file="index.tsx" />
                        <Typography variant="body2" fontWeight="bold" whiteSpace="nowrap" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
                            {config.app.name}
                        </Typography>
                    </Box>
                    <Box pt={5} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                        <MenuList items={menuItems} data-sentry-element="MenuList" data-sentry-source-file="index.tsx" />
                        <MenuList items={bottomMenuItemsFiltered} data-sentry-element="MenuList" data-sentry-source-file="index.tsx" />
                    </Box>
                </Box>
            </Drawer>
        </>;
};
export default memo(Sidebar);