import { FormEvent } from 'react';
import BillingApi from '@/api/methods/billing';
import { logger } from '@/utils/logger';
import { Box, Button } from '@mui/material';
import getStripe from './get-stripejs';
import type { CheckoutRequestBody } from '@/pages/api/stripe/checkout_sessions';
interface CheckoutFormProps {
  body: CheckoutRequestBody;
}
const CheckoutForm = ({
  body
}: CheckoutFormProps) => {
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await BillingApi.stripeCheckoutSession(body).then(async res => {
      if (res.status === 200) {
        logger.info('Checkout session created', res.data);
        const data = res.data!;
        const stripe = await getStripe();
        const {
          error
        } = await stripe!.redirectToCheckout({
          sessionId: data.id
        });
        if (error) {
          logger.error('Stripe checkout error', error);
        }
      } else {
        logger.error('Failed to create checkout session', res.data);
      }
    });
  };
  return <Box component="form" onSubmit={handleSubmit} data-sentry-element="Box" data-sentry-component="CheckoutForm" data-sentry-source-file="index.tsx">
            <Button type="submit" variant="contained" color="success" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                Checkout {body.amount} {body.currency}
            </Button>
        </Box>;
};
export default CheckoutForm;