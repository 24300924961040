
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'fluent:extension-20-regular',
    slug: 'browser-extensions',
    title: "Browser Extension Development | Enhance Web Experiences with Unrealos",
    description: "Unrealos specializes in custom browser extensions with cross-platform compatibility, advanced functionality, and robust security. Transform your web journey today.",
    fileName: 'content.mdx',
    Component,
}

export default data;