const IS_DEV = process.env.NODE_ENV === 'development';

const env = new class {
	FRONTEND_URL: string = process.env.FRONTEND_URL as string;
	DJANGO_URL: string = process.env.DJANGO_URL as string;
	MOCK_API: boolean = process.env.MOCK_API === 'true';
	GOOGLE_ID: string = process.env.GOOGLE_ID as string;
	GOOGLE_MAPS_API_KEY: string = process.env.GOOGLE_MAPS_API_KEY as string;
	STRIPE_PUBLIC_KEY: string = process.env.STRIPE_PUBLIC_KEY as string;
	BACKEND_SECRET: string = process.env.BACKEND_SECRET as string;

	isDev = IS_DEV;
	isProd = !IS_DEV;
	isMock = process.env.MOCK_MODE === 'true';
	isStorybook = process.env.STORYBOOK === 'true';

	// getApiUrl = Helper.getApiUrl;
	getApiUrl(postfix: string) {
		let path = postfix;

		// If the postfix is a full URL, use it as is
		if (postfix.startsWith('http')) {
			const url = new URL(postfix);
			path = url.pathname + url.search;
		}
		const url = `${this.DJANGO_URL}${path}`;
		return url;
	}

	// private getNextApiUrl(postfix: string) {
	// 	let url = `/api${postfix}`;

	// 	// If storybook, use the frontend URL
	// 	if (this.isStorybook) {
	// 		url = `${this.FRONTEND_URL}${url}`;
	// 	}

	// 	return url;
	// }

	// nextApis = {
	// 	stripe: {
	// 		checkoutSessions: this.getNextApiUrl('/stripe/checkout_sessions'),
	// 	},
	// };
}

export default env;
