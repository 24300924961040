import { memo } from 'react';
import useMedia from '@/hooks/useMedia';
import AppBarDesktop from './Desktop';
import AppBarMobile from './Mobile';
const Appbar = () => {
  // hooks
  const media = useMedia();
  if (media.up.sm) {
    return <AppBarDesktop />;
  }
  return <AppBarMobile data-sentry-element="AppBarMobile" data-sentry-component="Appbar" data-sentry-source-file="index.tsx" />;
};
export default memo(Appbar);