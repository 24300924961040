import { memo, useRef, useState } from 'react';
import UniversalDialog, { UniversalDialogRef } from '@/components/MuiDialogs/UniversalDialog';
import useEventTracker from '@/core/analytics/useEventTracker';
import Container from './Container';
const PaymentDialog = () => {
  // hooks
  const eventTracker = useEventTracker();

  // states
  const [open, setOpen] = useState(false);
  const dialogRef = useRef<UniversalDialogRef>(null);

  // events
  // commonEvents.paymentDialog.useListener(() => {
  //     handleOpen();
  //     eventTracker.billing.openDialog();
  // });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return <div data-sentry-component="PaymentDialog" data-sentry-source-file="index.tsx">
            <UniversalDialog ref={dialogRef} open={open} onClose={handleClose} title="Payment" showClose={true} loading={false} maxWidth="sm" transition="up" dividers={true} close={true} sx={{
      p: 10
    }} data-sentry-element="UniversalDialog" data-sentry-source-file="index.tsx">
                <Container data-sentry-element="Container" data-sentry-source-file="index.tsx" />
            </UniversalDialog>
        </div>;
};
export default memo(PaymentDialog);