import React, { useMemo } from 'react';
import AdminLayout from './AdminLayout';
import AuthLayout from './AuthLayout';
import CabinetLayout from './CabinetLayout';
import CommonLayout from './CommonLayout';
import PublicLayout from './PublicLayout';
import type { PageProps } from '@/types/pages';

// Combiner component to render layouts based on the config
interface Props {
  pageProps: PageProps;
  children: React.ReactNode;
}
const Combiner = ({
  pageProps,
  children
}: Props) => {
  // computed
  const layoutName = pageProps.config?.layout?.name;
  const isProtected = pageProps.config?.protected === true;

  // Memoize the layout to avoid unnecessary recalculation on each render
  const layout = useMemo(() => {
    const getLayout = () => {
      switch (layoutName) {
        case 'public':
          return <PublicLayout pageProps={pageProps}>
							{children}
						</PublicLayout>;
        case 'admin':
          return <AdminLayout>{children}</AdminLayout>;
        case 'cabinet':
          return <CabinetLayout>{children}</CabinetLayout>;
        case 'blank':
        default:
          return <>{children}</>;
      }
    };
    return getLayout();
  }, [layoutName, children]); // Dependencies are layoutName and children

  // Handle protected routes
  if (isProtected) {
    return <AuthLayout>{layout}</AuthLayout>;
  }
  return layout;
};
const DefaultLayout = (props: Props) => <>
		<CommonLayout data-sentry-element="CommonLayout" data-sentry-source-file="Builder.tsx" />
		<Combiner {...props} data-sentry-element="Combiner" data-sentry-source-file="Builder.tsx" />
	</>;
export default DefaultLayout;