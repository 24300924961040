
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'clarity:store-line',
    slug: 'nfts-marketplaces',
    title: "NFT Marketplace Development | Empower Digital Ownership with Unrealos",
    description: "Unrealos creates cutting-edge NFT marketplaces with blockchain integration, smart contracts, and tailored designs. Build your platform for the future of digital ownership today.",
    fileName: 'content.mdx',
    Component,
}

export default data;