import axios, {
    AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig
} from 'axios';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import https from 'https';

import env from '@/core/env';

import AuthApi from './methods/auth';

class Api {
    private instance: AxiosInstance;

    constructor() {
        this.instance = axios.create({
            // baseURL: env.DJANGO_URL, // uncomment if needed
            timeout: 10000,
            // Disable certificate verification
            httpsAgent: new https.Agent({
                rejectUnauthorized: false,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // --------------------------
        // REQUEST INTERCEPTOR
        // --------------------------
        this.instance.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                // Check if we're in a browser environment
                const isBrowser = typeof window !== 'undefined';
                const isServer = !isBrowser;

                // Get cookies only if in the browser
                const accessToken = isBrowser ? getCookie('access_token') : null;
                const userLang = isBrowser ? (getCookie('user_lang') || 'en') : 'en';

                // Resolve the timezone only if in the browser
                let timezone = 'UTC';
                if (isBrowser) {
                    try {
                        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
                    } catch (error) {
                        // Optionally log the error
                    }
                }

                // Set Authorization header if we have an access token
                if (accessToken) {
                    config.headers = config.headers || {};
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }

                // Language and timezone headers
                config.headers = config.headers || {};
                config.headers['Accept-Language'] = userLang;
                config.headers['X-Timezone'] = timezone;

                let url = config.url || '';

                if (isServer) {
                    url = `${env.DJANGO_URL}${url}`;
                }

                if (config.headers?.['x-mocked'] === 'true') {
                    // If mocked, set the base URL to the mock server
                    if (url && !url.startsWith('http') && !url.startsWith('/api/')) {
                        url = `/api/${url.replace(/^\/+/, '')}`;
                    }
                }

                // Set the final URL
                config.url = url;

                return config;
            },
            (error) => Promise.reject(error)
        );

        // --------------------------
        // RESPONSE INTERCEPTOR
        // --------------------------
        this.instance.interceptors.response.use(
            (response: AxiosResponse) => {
                // If successful, just return the response
                return response;
            },
            async (error) => {
                const originalRequest = error.config;

                // Handle 401 errors and attempt token refresh
                if (error.response?.status === 401 && originalRequest && !originalRequest._retry) {
                    originalRequest._retry = true;

                    const refreshToken = getCookie('refresh_token');
                    if (typeof refreshToken === 'string') {
                        try {
                            const { data } = await AuthApi.tokenRefresh({ refresh: refreshToken });

                            // Update the access token and retry the request
                            setCookie('access_token', data.access);
                            originalRequest.headers['Authorization'] = `Bearer ${data.access}`;
                            const retryResponse = await this.instance(originalRequest);

                            return {
                                success: true,
                                data: retryResponse.data,
                                status: retryResponse.status,
                            };
                        } catch {
                            deleteCookie('access_token');
                            deleteCookie('refresh_token');
                        }
                    }
                }

                // Return a consistent error response
                return {
                    error: error.message,
                    status: error.response?.status || null,
                    data: error.response?.data || null,
                };
            }
        );

    }

    /**
     * Private method to generate custom headers if needed.
     * e.g. for mocking or any additional headers.
     */
    private _getHeaders(
        mocked: boolean | null = null,
        additionalHeaders: AxiosRequestConfig['headers']
    ): AxiosRequestConfig['headers'] {
        const isMockedDefault = env.MOCK_API ? 'true' : 'false';
        const isMocked = mocked === null ? isMockedDefault : mocked;

        return {
            ...additionalHeaders,
            'x-mocked': isMocked.toString(),
        };
    }

    // -----------------------------------
    // GET
    // -----------------------------------
    async get<T = any>({
        url,
        mocked = null,
        params = {},
        config = {},
    }: {
        url: string;
        mocked?: boolean | null;
        params?: Record<string, any>;
        config?: AxiosRequestConfig;
    }): Promise<AxiosResponse<T>> {
        const headers = this._getHeaders(mocked, config.headers || {});
        return this.instance.get<T>(url, { ...config, headers, params });
    }

    // -----------------------------------
    // POST
    // -----------------------------------
    async post<T = any>({
        url,
        data,
        mocked = null,
        params = {},
        config = {},
    }: {
        url: string;
        data?: any;
        mocked?: boolean | null;
        params?: Record<string, any>;
        config?: AxiosRequestConfig;
    }): Promise<AxiosResponse<T>> {
        const headers = this._getHeaders(mocked, config.headers || {});
        return this.instance.post<T>(url, data, { ...config, headers, params });
    }

    // -----------------------------------
    // PUT
    // -----------------------------------
    async put<T = any>({
        url,
        data,
        mocked = null,
        params = {},
        config = {},
    }: {
        url: string;
        data?: any;
        mocked?: boolean | null;
        params?: Record<string, any>;
        config?: AxiosRequestConfig;
    }): Promise<AxiosResponse<T>> {
        const headers = this._getHeaders(mocked, config.headers || {});
        return this.instance.put<T>(url, data, { ...config, headers, params });
    }

    // -----------------------------------
    // DELETE
    // -----------------------------------
    async delete<T = any>({
        url,
        mocked = null,
        params = {},
        config = {},
    }: {
        url: string;
        mocked?: boolean | null;
        params?: Record<string, any>;
        config?: AxiosRequestConfig;
    }): Promise<AxiosResponse<T>> {
        const headers = this._getHeaders(mocked, config.headers || {});
        return this.instance.delete<T>(url, { ...config, headers, params });
    }
}

// Export a singleton instance
const api = new Api();
export default api;
