import Box, { BoxProps } from '@mui/material/Box';
import { darken, useTheme } from '@mui/material/styles';
interface RoundBoxProps {
  children: React.ReactNode;
  variant: 'top' | 'bottom' | 'both';
  shift?: boolean;
  darken: number;
  bgcolor?: 'primary' | 'secondary' | 'background';
  sx?: BoxProps['sx'];
  zIndex?: number;
}
const RoundBox = ({
  children,
  variant,
  shift,
  bgcolor,
  sx,
  zIndex,
  ...props
}: RoundBoxProps) => {
  const theme = useTheme();
  const makeBorderRadius = () => {
    const options = {
      xs: theme.spacing(5),
      md: theme.spacing(20)
    };
    const optionsZero = {
      xs: '0',
      md: '0'
    };
    let borderRadius = {
      borderTopRightRadius: options,
      borderTopLeftRadius: options,
      borderBottomRightRadius: options,
      borderBottomLeftRadius: options
    };
    if (variant === 'top') {
      borderRadius.borderBottomRightRadius = optionsZero;
      borderRadius.borderBottomLeftRadius = optionsZero;
    }
    if (variant === 'bottom') {
      borderRadius.borderTopRightRadius = optionsZero;
      borderRadius.borderTopLeftRadius = optionsZero;
    }
    return borderRadius;
  };
  return <Box sx={{
    ...makeBorderRadius(),
    ...(shift === true && {
      ...(variant === 'top' && {
        pb: 15,
        mb: -15
      }),
      ...(variant === 'bottom' && {
        pt: 15,
        mt: -15
      })
    }),
    position: 'relative',
    overflow: 'hidden',
    bgcolor: () => {
      if (!bgcolor) return undefined;
      switch (bgcolor) {
        case 'primary':
          return darken(theme.palette.primary.main, props.darken);
        case 'secondary':
          return darken(theme.palette.secondary.main, props.darken);
        case 'background':
          return darken(theme.palette.background.default, props.darken);
      }
    },
    zIndex: zIndex
  }} data-sentry-element="Box" data-sentry-component="RoundBox" data-sentry-source-file="RoundBox.tsx">
            <Box sx={{
      ...sx
    }} data-sentry-element="Box" data-sentry-source-file="RoundBox.tsx">
                {children}
            </Box>
        </Box>;
};
export default RoundBox;