// @components
import { ButtonLink } from '@/components/Link';
import config from '@/core/config';
import routes from '@/core/routes';
// blocks
import CustomThemeProvider from '@/theme/ThemeProvider/Custom';
// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// mui
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Forbes from './Forbes';
export default function Press() {
  const logotypes = [config.assets.logotypes.lv80, config.assets.logotypes.coinTelegraph, config.assets.logotypes.forbes, config.assets.logotypes.vc];
  return <Stack spacing={{
    xs: 5,
    md: 15
  }} data-sentry-element="Stack" data-sentry-component="Press" data-sentry-source-file="index.tsx">
      <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: {
        xs: 'column',
        md: 'row'
      },
      gap: 5
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          We are in the press.
        </Typography>
        <ButtonLink href={routes.pages.contact.path} endIcon={<ArrowForwardIcon />} variant="outlined" color="inherit" data-sentry-element="ButtonLink" data-sentry-source-file="index.tsx">
          Press enquiries
        </ButtonLink>
      </Box>

      <Forbes data-sentry-element="Forbes" data-sentry-source-file="index.tsx" />

      <Box sx={{
      borderRadius: 2,
      overflow: 'hidden'
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <CustomThemeProvider mode="light" data-sentry-element="CustomThemeProvider" data-sentry-source-file="index.tsx">
          <Box sx={{
          p: {
            xs: 5,
            md: 10
          },
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(130px, 1fr))',
          gap: 5
        }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
            {logotypes.map((logotype, index) => <Box key={index} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
                <Box component="img" src={logotype} sx={{
              height: {
                xs: 35,
                sm: 80
              }
            }} />
              </Box>)}
          </Box>
        </CustomThemeProvider>
      </Box>

    </Stack>;
}