import NextLink from 'next/link';
import { memo } from 'react';
import { ButtonLink } from '@/components/Link';
import { useAuthContext } from '@/context';
import routes from '@/core/routes';
import LogoAnimation from '@/snippets/LogoAnimation';
import { Box, CircularProgress, Typography } from '@mui/material';
import InitChildren from './InitChildren';
interface AuthLayoutProps {
  children: React.ReactNode;
}
const AuthLayout = ({
  children
}: AuthLayoutProps) => {
  // context
  const {
    state: {
      loginStatus
    },
    manager
  } = useAuthContext();

  // Renders loading spinner if user is in the process of logging in
  if (loginStatus === 'logging') {
    return <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
                <CircularProgress size={20} />
            </Box>;
  }

  // If the user is not logged in, show the login prompt
  if (!manager.isLogged) {
    return <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
      height: '100vh',
      flexDirection: 'column'
    }}>
                <Box component={NextLink} href={routes.home}>
                    <LogoAnimation size={100} followCursor />
                </Box>

                <Typography variant="h3" gutterBottom>
                    You are not logged in
                </Typography>
                <Box>
                    <ButtonLink data-button-gradient sx={{
          borderRadius: 50,
          '&.MuiButton-root': {
            px: 10
          }
        }} href={routes.auth.login.path} variant='contained' color='primary' size='large'>
                        Login
                    </ButtonLink>
                </Box>
            </Box>;
  }

  // If the user is logged in, render the children (the protected content)
  return <InitChildren data-sentry-element="InitChildren" data-sentry-component="AuthLayout" data-sentry-source-file="index.tsx">
            {children}
        </InitChildren>;
};
export default memo(AuthLayout);