
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'fluent:brain-circuit-20-regular',
    slug: 'ai-data-science',
    title: "AI and Data Science Solutions | Unlock Business Potential with Unrealos",
    description: "Discover the power of AI, machine learning, and data science with Unrealos. Enhance decision-making, automate processes, and deliver personalized customer experiences.",
    fileName: 'content.mdx',
    Component,
}

export default data;