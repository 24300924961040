import NextLink from 'next/link';
import { memo } from 'react';
import StickyBox from '@/components/StickyBox';
import config from '@/core/config';
import routes from '@/core/routes';
import { Box } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import DrawerMenu from './DrawerMenu';
const AppbarMobile = () => {
  // hooks
  const theme = useTheme<Theme>();
  return <StickyBox zIndex={theme.zIndex.appBar} data-sentry-element="StickyBox" data-sentry-component="AppbarMobile" data-sentry-source-file="Mobile.tsx">
            {({
      isFixed
    }) => <Box component="header" sx={{
      py: 3,
      pl: 5,
      pr: 2,
      height: theme => theme.mixins.appBarHeight,
      position: 'relative',
      zIndex: theme => theme.zIndex.appBar,
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      ...(isFixed && {
        backgroundColor: theme => theme.palette.background.default,
        boxShadow: theme => theme.shadows[4]
      })
    }}>
                    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 5
      }}>
                        <Box component={NextLink} href={routes.home} sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          textDecoration: 'none',
          color: 'inherit'
        }}>
                            <Box component="img" src={config.static.logo.svg} alt={config.app.name} sx={{
            width: 90
          }} />
                        </Box>
                    </Box>
                    <Box flexGrow={1} />
                    <DrawerMenu />
                </Box>}
        </StickyBox>;
};
export default memo(AppbarMobile);