// ** MUI Imports
import { Theme } from '@mui/material/styles';

// ** Theme Config Imports
import media from '../utils/media';

const formControlLabel = (theme: Theme) => ({
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '& .MuiTypography-root': {
          userSelect: 'none',
          fontSize: '1rem',
          [media.max.sm]: {
            fontSize: '0.9rem',
          },
        }
      },
    }
  },
})

export default formControlLabel
