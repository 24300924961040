import { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import Common from './Common';
import Container from './Container';
import ContextProvider from './context';
interface AppProps {
  children: React.ReactNode;
}
const CabinetLayout = memo(({
  children
}: AppProps) => {
  // computed
  const memorizedChildren = useMemo(() => children, [children]);
  return <Box>
            <Common />

            <Box sx={{
      display: 'flex',
      minHeight: '100vh',
      bgcolor: 'background.default'
    }}>
                <Container>
                    {memorizedChildren}
                </Container>

            </Box>
        </Box>;
});
export default (props: AppProps) => <ContextProvider>
        <CabinetLayout {...props} />
    </ContextProvider>;