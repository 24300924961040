import mediaQuery from 'css-mediaquery';

import config from '@/core/config';
import { createTheme, PaletteMode, responsiveFontSizes, Theme } from '@mui/material';

import breakpoints from './breakpoints';
import overrides from './overrides';
import Palette from './palette';
import shadows from './shadows';
import spacing from './spacing';
import themeConfig from './themeConfig';
import { Mode } from './types';
import { typography } from './typography';

const { mode: defaultMode } = themeConfig;

export default function createAppTheme(mode: Mode = defaultMode, deviceType?: string): Theme {
  const isMobile = deviceType === 'mobile';
  const ssrWidth = isMobile ? '0px' : deviceType === 'tablet' ? '768px' : '1024px';

  const baseTheme = createTheme({
    palette: new Palette(mode).generatePalette(),
    shadows: shadows(mode),
    ...spacing,
    breakpoints: breakpoints(),
    shape: {
      borderRadius: 8,
    },
    typography,
    mixins: {
      toolbar: {
        background: '#fff',
        minHeight: 64,
        '@media (min-width:0px) and (orientation: landscape)': {
          minHeight: 64,
        },
        '@media (min-width:600px)': {
          minHeight: 80,
        },
      },
      appBarHeight: 'var(--appbar-height)',
      contentHeight: 'var(--content-height)',
      documentHeight: 'var(--document-height, 100vh)',
    },
  });

  const themeWithOverrides = {
    ...baseTheme,
    components: {
      ...overrides(baseTheme, themeConfig),
      MuiUseMediaQuery: {
        defaultProps: {
          ssrMatchMedia: (query: string) => ({
            matches: mediaQuery.match(query, {
              width: ssrWidth,
            }),
          }),
        },
      },
    },
  };

  const themeWithResponsiveFonts = responsiveFontSizes(themeWithOverrides, {
    // Fine-tune font scaling behavior if needed
    // factor: 2, // Adjust scaling factor
    // breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'], // Customize responsive breakpoints
    // disableAlign: true, // Disable text alignment adjustments
  });

  if (typeof window !== 'undefined') {
    // Attach theme to window for debugging
    (window as any).theme = themeWithResponsiveFonts;
  }

  return themeWithResponsiveFonts;
}


/**
 * Export pre-configured theme
 */
export const defaultTheme = createAppTheme(config.theme.mode as PaletteMode);