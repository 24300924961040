// ** MUI Imports
import { Theme } from '@mui/material/styles';

const List = (theme: Theme) => {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          marginRight: theme.spacing(2.25),
          color: theme.palette.text.secondary,
        },
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 0,
          marginRight: theme.spacing(4)
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        dense: {
          '& .MuiListItemText-primary': {
            color: theme.palette.text.primary
          },
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          fontWeight: 500,

          lineHeight: '110%',
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          // backgroundColor: 'transparent',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            }
          }
        },
      }
    }
  }
}

export default List
