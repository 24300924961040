import NextLink from 'next/link';
import { memo } from 'react';
import { Link } from '@/components/Link';
import config from '@/core/config';
import routes from '@/core/routes';
import useMedia from '@/hooks/useMedia';
import { Box } from '@mui/material';
import { industries, menu, MenuItem } from './data';
import DrawerMenu from './DrawerMenu';
const AppbarDesktop = () => {
  // hooks
  const media = useMedia();
  const renderList = (items: MenuItem[]) => {
    return <Box sx={{
      display: 'flex',
      gap: 5
    }} data-sentry-element="Box" data-sentry-component="renderList" data-sentry-source-file="Desktop.tsx">
                {items.map((item, index) => <Link key={index} href={item.href} variant='subtitle1' sx={{
        fontWeight: theme => theme.typography.fontWeightMedium,
        color: 'text.primary',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        '& svg': {
          color: 'text.secondary'
        }
      }}>
                        {item.icon}
                        {item.name}
                    </Link>)}
            </Box>;
  };
  return <Box data-sentry-element="Box" data-sentry-component="AppbarDesktop" data-sentry-source-file="Desktop.tsx">
            <Box component="header" sx={{
      py: 3,
      px: 5,
      height: theme => theme.mixins.appBarHeight,
      display: 'flex',
      alignItems: 'center',
      gap: 5
    }} data-sentry-element="Box" data-sentry-source-file="Desktop.tsx">
                <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 5
      }} data-sentry-element="Box" data-sentry-source-file="Desktop.tsx">
                    <Box component={NextLink} href={routes.home} sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          textDecoration: 'none',
          color: 'inherit',
          mr: 5
        }} data-sentry-element="Box" data-sentry-source-file="Desktop.tsx">
                        <Box component="img" src={config.static.logo.svg} alt={config.app.name} sx={{
            width: 110
          }} data-sentry-element="Box" data-sentry-source-file="Desktop.tsx" />
                    </Box>

                    {media.up.lg && renderList(industries)}
                </Box>
                <Box flexGrow={1} data-sentry-element="Box" data-sentry-source-file="Desktop.tsx" />
                {media.up.md && <Box sx={{
        mr: 2
      }}>
                        {renderList(menu)}
                    </Box>}

                {media.down.lg && <Box mr={-2}>
                        <DrawerMenu />
                    </Box>}

            </Box>
        </Box>;
};
export default memo(AppbarDesktop);