import { memo } from 'react';
import { RenderPropSticky } from 'react-sticky-el';
import type { CommonProps, RenderFuncArgs } from 'react-sticky-el/lib/types';
interface StickyBoxProps {
  children: (args: RenderFuncArgs) => React.ReactNode;
  boundaryElement?: string;
  config?: Partial<CommonProps>;
  zIndex?: number;
}
const StickyBox = ({
  children,
  boundaryElement,
  config,
  zIndex
}: StickyBoxProps) => {
  return <RenderPropSticky boundaryElement={boundaryElement} hideOnBoundaryHit={false} {...config} data-sentry-element="RenderPropSticky" data-sentry-component="StickyBox" data-sentry-source-file="index.tsx">
      {({
      isFixed,
      wrapperStyles,
      wrapperRef,
      holderStyles,
      holderRef
    }) => <div ref={holderRef} style={{
      zIndex,
      ...holderStyles
    }}>
          <div ref={wrapperRef} style={wrapperStyles}>
            {children({
          isFixed,
          wrapperStyles,
          wrapperRef,
          holderStyles,
          holderRef
        })}
          </div>
        </div>}
    </RenderPropSticky>;
};
export default memo(StickyBox);