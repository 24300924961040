
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'hugeicons:chat-bot',
    slug: 'bots-automation',
    title: "Bots and Automation Solutions | Streamline Your Business with Unrealos",
    description: "Unrealos specializes in custom bots and automation solutions to enhance productivity, reduce costs, and deliver personalized customer experiences. Automate your success today.",
    fileName: 'content.mdx',
    Component,
}

export default data;