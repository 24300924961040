import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
const PageProgress = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const handleRouteChangeStart = (url: string, {
      shallow
    }: {
      shallow: boolean;
    }) => {
      if (!shallow) {
        setLoading(true);
      }
    };
    const handleRouteChangeEnd = () => {
      setLoading(false);
    };
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);
    router.events.on('routeChangeError', handleRouteChangeEnd);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeEnd);
      router.events.off('routeChangeError', handleRouteChangeEnd);
    };
  }, [router.events]);
  if (loading) {
    return <Box sx={{
      width: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: theme => theme.zIndex.drawer + 1
    }}>
        <LinearProgress sx={{
        backgroundColor: theme => theme.palette.primary.light
      }} />
      </Box>;
  }
  return null;
};
export default PageProgress;