import type { UserData } from '@/api/methods/auth';
import { useBase } from './useBase';

export const useAuthEvents = () => {
    const openDialog = useBase<void>('authOpenDialog');

    const userAuth = useBase<{ isLogged: boolean; userData: UserData | null }>('userAuth');

    return {
        openDialog,
        userAuth,
    };
};
