import ThemeToggle from './ThemeToggle';
const Switchers = () => {
  return <>

            <ThemeToggle data-sentry-element="ThemeToggle" data-sentry-source-file="index.tsx" />

            {/* <SwitcherWrapper
                label="Tooltips"
                checked={settings.showTooltips}
                onChange={() => {
                    settings.switchTooltips();
                }}
             />
             <SwitcherWrapper
                label="Tips"
                checked={settings.showTips}
                onChange={() => {
                    settings.switchTips();
                }}
             /> */}


        </>;
};
export default Switchers;