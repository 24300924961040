
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'hugeicons:web-design-02',
    slug: 'web-apps-pwas',
    title: "Web App & PWA Development | Versatile Solutions by Unrealos",
    description: "Unrealos specializes in high-performance web apps and PWAs with cross-platform compatibility, offline functionality, and responsive design. Transform your web presence today.",
    fileName: 'content.mdx',
    Component,
}

export default data;