// ** MUI Imports
import { Theme } from '@mui/material/styles';

// ** Theme Type Import
import { Skin } from '../types';
import media from '../utils/media';

const Menu = (theme: Theme, skin: Skin) => {
  const boxShadow = () => {
    if (skin === 'bordered') {
      return theme.shadows[0]
    } else if (theme.palette.mode === 'light') {
      return theme.shadows[8]
    } else return theme.shadows[9]
  }

  return {
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiMenu-paper': {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.background.paper,
            boxShadow: boxShadow(),
            ...(skin === 'bordered' && { border: `1px solid ${theme.palette.divider}` })
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          [media.min.xs]: {
            fontSize: theme.typography.body2.fontSize,
          },
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected
          },
          // '&.Mui-disabled': {
          //   opacity: 1,
          //   color: theme.palette.text.disabled
          // },
        }
      }
    }
  }

}

export default Menu
