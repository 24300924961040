
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'ph:contactless-payment',
    slug: 'payment-gateways',
    title: "Payment Gateway Solutions | Secure and Seamless Transactions by Unrealos",
    description: "Unrealos specializes in custom payment gateway development with seamless integration, robust security, and support for multiple payment options. Elevate your transaction processes today.",
    fileName: 'content.mdx',
    Component,
}

export default data;