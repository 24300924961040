
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'hugeicons:software-license',
    slug: 'custom-software',
    title: "Custom Software Development | Tailored Solutions by Unrealos",
    description: "Unrealos specializes in custom software development designed to scale, integrate seamlessly, and enhance productivity. Build your competitive edge with tailored solutions.",
    fileName: 'content.mdx',
    Component,
}

export default data;