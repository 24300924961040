import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import AuthApi from '@/api/methods/auth';
import useEventTracker from '@/core/analytics/useEventTracker';
import env from '@/core/env';
import routes, { routesPreset } from '@/core/routes';
import { useAuthEvents } from '@/events';
import { clearStorage } from '@/hooks/useStorage';
import { logger } from '@/utils/logger';

import type { UserData } from '@/api/methods/auth';

export type AuthState = {
    token: string | null;
    defaultEmail: string;
    userData: UserData | null;
    loginStatus: "idle" | "logging" | "error" | "success";
    error: string | null;
}

export const useAuth = () => {
    const [state, setState] = useState<AuthState>({
        token: null,
        defaultEmail: '',
        userData: null,
        loginStatus: 'idle',
        error: null,
    });

    // hooks
    const router = useRouter();
    const eventTracker = useEventTracker();
    const authEvents = useAuthEvents();

    // computed
    const isLogged = state.token !== null && state.loginStatus === 'success';

    const dispatch = (action: Partial<AuthState>) => {
        setState((prev) => ({ ...prev, ...action }));
    };

    useEffect(() => {
        // Load default email
        const defaultEmail = localStorage.getItem('auth_default_email');
        if (defaultEmail) {
            dispatch({ defaultEmail });
        }

        // Auto init
        manager.autoInit();
    }, []);

    useEffect(() => {
        authEvents.userAuth.emit({
            isLogged,
            userData: state.userData,
        })
    }, [isLogged]);

    const backUrl = new class {
        get currentUrl() {
            return router.asPath;
        }
        save = () => {
            if (router.pathname.startsWith('/auth')) return;
            sessionStorage.setItem('auth_back_url', this.currentUrl);
        }
        private get savedUrl() {
            return sessionStorage.getItem('auth_back_url');
        }
        private removeSavedUrl = () => {
            sessionStorage.removeItem('auth_back_url');
        }
        redirect = () => {
            const savedUrl = this.savedUrl;

            if (savedUrl) {
                const isRestricted = savedUrl === '/' || savedUrl.startsWith('/auth');
                if (!isRestricted) {
                    this.removeSavedUrl();
                    router.push(savedUrl);
                    return;
                }
            }

            router.push(routesPreset.userLogged);
        }
    }

    const manager = new class {

        get isLogged() {
            return state.token !== null && state.loginStatus === 'success';
        }

        get isAdmin() {
            return state.userData?.profile.is_admin_role || false;
        }

        setDefaultEmail = (email: string) => {
            dispatch({ defaultEmail: email });
            localStorage.setItem('auth_default_email', email);
        };

        authenticate = async (params: { email: string, password: string, two_factor_token?: string }) => {

            dispatch({ loginStatus: 'logging' });

            // save back url
            backUrl.save();

            return AuthApi.tokenObtain(params).then((res) => {
                if (res.status === 200) {
                    const data = res.data;
                    const { access, refresh } = data || {};

                    const expires = new Date();
                    expires.setDate(expires.getDate() + 30);
                    setCookie('access_token', access, { expires });
                    setCookie('refresh_token', refresh, { expires });

                    dispatch({ token: access, loginStatus: 'success' });
                    this.checkToken();

                } else if (res.status === 403) {
                    dispatch({ loginStatus: 'error', error: 'Email not verified' });
                    window.common.alert({
                        title: 'Email not verified',
                        message: 'Check your email and verify your account.',
                    });
                } else if (res.status === 409) {
                    this.banUser();
                    return;
                } else {
                    dispatch({ error: 'Login failed', loginStatus: 'error' });
                }
                return res;
            }).catch((err) => {
                dispatch({ error: 'Login failed', loginStatus: 'error' });
                logger.error('authenticate', err);
                return err;
            });
        };

        banUser = async () => {

            await window.common.alert({
                title: 'Bye-bye, we don\'t know you anymore',
                message: 'You have been logged out because your account was deleted or disabled.',
            })

            this.logout();
        }

        checkToken = async () => {

            await AuthApi.checkToken().then((res) => {
                if (res.status === 200) {
                    const userData = res.data;
                    dispatch({ userData, loginStatus: 'success' });

                } else if (res.status === 409) {
                    this.banUser();
                    return;
                } else {
                    logger.error('Auth check failed');
                    window.common.alert({
                        title: 'Something went wrong',
                        message: 'We have some problems with your account. Please try to login again.',
                    });
                    if (env.isProd) this.logout();
                }
            }).catch(() => {
                logger.error('Auth check failed');
                if (env.isProd) this.logout();
            });
        };

        loadToken = () => {
            const token = getCookie('access_token');
            if (typeof token === 'string') {
                dispatch({ token });
                this.checkToken();
            } else {
                dispatch({ loginStatus: 'error' });
            }
        };

        setTokenData = (access: string, refresh: string) => {

            const expires = new Date();
            expires.setDate(expires.getDate() + 30);
            setCookie('access_token', access, { expires });
            setCookie('refresh_token', refresh, { expires });

            dispatch({ token: access });
            this.checkToken();
        };

        logout = () => {
            logger.info('Logout');

            // Track the logout event
            eventTracker.auth.logout();

            deleteCookie('access_token');
            deleteCookie('refresh_token');

            dispatch({ token: null, userData: null, loginStatus: 'idle' });

            clearStorage('local');
            clearStorage('session');
        };

        handleLogout = () => {
            this.logout();
            router.push(routes.auth.login.path);
        };

        autoInit = () => {
            const token = router.query.token as string;

            // If token is in query params then save it to cookies
            if (token && router.pathname === routes.auth.login.path) {

                const expires = new Date();
                expires.setDate(expires.getDate() + 30);
                setCookie('access_token', token, { expires });

                const newQuery = { ...router.query };
                delete newQuery.token;
                router.replace({ query: newQuery }, undefined, { shallow: true });
            }

            this.loadToken();
        };

        redirectIfLogged = () => {
            backUrl.redirect();
        };
    };

    return {
        state,
        manager,
        backUrl,
    };
};
