
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'circum:mobile-3',
    slug: 'mobile-apps',
    title: "Mobile App Development | iOS & Android Solutions by Unrealos",
    description: "Unrealos specializes in scalable, secure, and high-performance mobile app development for iOS and Android. Elevate your mobile experience with custom solutions tailored to your needs.",
    fileName: 'content.mdx',
    Component,
}

export default data;