import { languages } from 'countries-list';

type Language = {
    code: string;
    name: string;
};
export const languageList: Language[] = Object.entries(languages).map(([code, { name }]) => ({
    code,
    name,
}));

const popularLanguageCodes = [
    'en', // English
    'zh', // Chinese
    'es', // Spanish
    'hi', // Hindi
    'ar', // Arabic
    'fr', // French
    'ru', // Russian
    'pt', // Portuguese
    'de', // German
    'ja', // Japanese
    'ko', // Korean
    'it', // Italian
    'tr', // Turkish
    'fa', // Persian
    'nl', // Dutch
    'sv', // Swedish
    'pl', // Polish
    'uk', // Ukrainian
    'th', // Thai
    'id'  // Indonesian
];

export const popularLanguages: Language[] = Object.entries(languages)
    .filter(([code]) => popularLanguageCodes.includes(code))
    .map(([code, { name }]) => ({
        code,
        name,
    }));