import StickyBox from '@/components/StickyBox';
import { Box, Container } from '@mui/material';
import Menu from './Menu';
interface AdminLayoutProps {
  children: React.ReactNode;
}
const AdminLayout = ({
  children
}: AdminLayoutProps) => {
  return <Box id="admin-layout" sx={{
    display: 'grid',
    gridTemplateColumns: '230px 1fr',
    minHeight: '100vh'
  }} data-sentry-element="Box" data-sentry-component="AdminLayout" data-sentry-source-file="index.tsx">
            <Box sx={{
      bgcolor: 'action.hover'
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                <StickyBox boundaryElement='#admin-layout' data-sentry-element="StickyBox" data-sentry-source-file="index.tsx">
                    {({
          isFixed
        }) => <Menu />}
                </StickyBox>
            </Box>
            <Box py={10} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                <Container maxWidth="lg" data-sentry-element="Container" data-sentry-source-file="index.tsx">
                    {children}
                </Container>
            </Box>
        </Box>;
};
export default AdminLayout;