
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'mingcute:ai-line',
    slug: 'ai-solutions',
    title: "AI Solutions | Transform Your Business with Unrealos",
    description: "Unrealos provides tailored AI solutions to automate workflows, deliver data-driven insights, and enhance customer experiences. Embrace innovation with ethical AI practices.",
    fileName: 'content.mdx',
    Component,
}

export default data;