import { EventParams } from './';

export type Category = 'auth' | 'manager' | 'dashboard' | 'pages' | 'system' | 'cabinet' | 'billing';


const createEvent = (event: (params: EventParams) => void) => ({
    system: {
        appLoaded: () => event({ eventType: 'event', category: 'system', action: 'app-loaded' }),
        appCrashed: (errorDetails?: { error: string; stack?: string; source?: string; line?: number; column?: number }) => {
            event({
                eventType: 'event',
                category: 'system',
                action: 'app-crashed',
                additionalData: errorDetails,
            });
        },
    },
    auth: {
        registration: {
            start: () => event({ eventType: 'event', category: 'auth', action: 'start-registration' }),
            success: () => event({ eventType: 'event', category: 'auth', action: 'successful-registration' }),
        },
        authorization: {
            start: () => event({ eventType: 'event', category: 'auth', action: 'start-authorization' }),
            success: () => event({ eventType: 'event', category: 'auth', action: 'successful-authorization' }),
        },
        logout: () => event({ eventType: 'event', category: 'auth', action: 'logout' }),
    },
    manager: {
        imageUpload: {
            selectFile: (params: { variant: 'dragAndDrop' | 'fileInput' }) => event({ eventType: 'event', category: 'manager', action: 'select-image-file', label: params.variant }),
            localUpload: () => event({ eventType: 'event', category: 'manager', action: 'local-image-upload' }),
            success: () => event({ eventType: 'event', category: 'manager', action: 'successful-image-upload' }),
        },
        imageCrop: {
            success: () => event({ eventType: 'event', category: 'manager', action: 'successful-crop' }),
        },
        imageGenerator: {
            openDialog: () => event({ eventType: 'event', category: 'manager', action: 'open-image-generator-dialog' }),
            placeImage: () => event({ eventType: 'event', category: 'manager', action: 'place-image' }),
        },
        videoSettings: {
            orientationSelection: (orientation: string) => event({ eventType: 'event', category: 'manager', action: 'select-video-orientation', label: orientation }),
            durationSelection: (duration: number) => event({ eventType: 'event', category: 'manager', action: 'select-duration', value: duration }),
            effectSelection: (effectName: string) => event({ eventType: 'event', category: 'manager', action: 'select-effect', label: effectName }),
        },
        videoGeneration: {
            start: () => event({ eventType: 'event', category: 'manager', action: 'start-video-generation' }),
            success: () => event({ eventType: 'event', category: 'manager', action: 'successful-video-generation' }),
        },
        actions: {
            saveTask: () => event({ eventType: 'event', category: 'manager', action: 'save-task' }),
            recreateTask: () => event({ eventType: 'event', category: 'manager', action: 'recreate-task' }),
            downloadClick: () => event({ eventType: 'event', category: 'manager', action: 'click-download-video' }),
            videoSharingToggle: (is_shared: boolean) => event({ eventType: 'event', category: 'manager', action: 'toggle-video-sharing', label: is_shared ? 'on' : 'off' }),
            videoFeedClick: (params: { taskUuid2: string; videoTitle: string }) => event({ eventType: 'event', category: 'manager', action: 'click-on-video-in-feed', label: params.videoTitle }),
        },
    },
    cabinet: {
        projectCreation: {
            start: () => event({ eventType: 'event', category: 'cabinet', action: 'start-project-creation' }),
            success: () => event({ eventType: 'event', category: 'cabinet', action: 'successful-project-creation' }),
        },
        projectEditing: {
            start: () => event({ eventType: 'event', category: 'cabinet', action: 'start-project-editing' }),
            success: () => event({ eventType: 'event', category: 'cabinet', action: 'successful-project-editing' }),
        },
    },
    pages: {
        visitMain: () => event({ eventType: 'page-view', category: 'pages', action: 'visit-main' }),
        visitDashboard: () => event({ eventType: 'page-view', category: 'pages', action: 'visit-dashboard' }),
        visitManager: () => event({ eventType: 'page-view', category: 'pages', action: 'visit-manager' }),
        visitAuth: (pageTitle: string) => event({
            eventType: 'page-view',
            category: 'pages',
            action: 'visit-auth',
            label: pageTitle, // Log what auth page was visited (e.g., 'login', 'register')
        }),
        playlist: {
            view: (params: { taskUuid2: string; videoTitle: string }) => event({
                eventType: 'page-view',
                category: 'pages',
                action: 'visit-public-playlist',
                label: `${params.videoTitle} (${params.taskUuid2})`,
            }),
            videoClick: (params: { taskUuid2: string; videoTitle: string }) => event({
                eventType: 'event',
                category: 'pages',
                action: 'click-on-video-in-playlist',
                label: `${params.videoTitle} (${params.taskUuid2})`,
            }),
            notFound: () => event({ eventType: 'page-view', category: 'pages', action: 'playlist-not-found' }),
        }
    },
    billing: {
        openDialog: () => event({ eventType: 'event', category: 'billing', action: 'open-billing-dialog' }),

    },
});

export default createEvent;
