import { memo } from 'react';
import { Box } from '@mui/material';
import Appbar from './Appbar';
import Footer from './Footer';
import type { PageProps } from '@/types/pages';
interface Props {
  children: React.ReactNode;
  pageProps: PageProps;
}
const PublicLayout = ({
  children,
  pageProps
}: Props) => {
  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  }} data-sentry-element="Box" data-sentry-component="PublicLayout" data-sentry-source-file="index.tsx">
            <Appbar data-sentry-element="Appbar" data-sentry-source-file="index.tsx" />
            <Box component="main" sx={{
      flexGrow: 1
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                {children}
            </Box>

            {pageProps.config.layout?.settings?.footerSize !== 'hidden' && <Footer />}

        </Box>;
};
export default memo(PublicLayout);