// ** MUI Imports
import { Theme } from '@mui/material/styles';

// ** Theme Type Import
import { Skin } from '../types';

const Autocomplete = (theme: Theme, skin: Skin) => {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          ...(skin === 'bordered' && { boxShadow: 'none', border: `1px solid ${theme.palette.divider}` }),
          borderRadius: theme.shape.borderRadius,
        },
        listbox: { // Target the list box that contains the options
          fontSize: '1rem',
        },
        tag: {
          fontSize: '1rem',
        },
        // inputRoot: {
        //   flexWrap: 'nowrap',
        //   '& > span.MuiAutocomplete-tag': {
        //     fontSize: '1rem',
        //   }
        // },
        // root: {
        //   '&.Mui-focused': {
        //     '& .MuiAutocomplete-inputRoot': {
        //       flexWrap: 'wrap',
        //     }
        //   }
        // }
      }
    }
  }
}

export default Autocomplete
