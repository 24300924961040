import { Box, PaletteMode } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import mui from '../mui';
interface ThemeProviderProps {
  children: React.ReactNode;
  mode: PaletteMode;
}
const CustomThemeProvider = ({
  children,
  mode
}: ThemeProviderProps) => {
  const theme = mui(mode);
  return <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-component="CustomThemeProvider" data-sentry-source-file="Custom.tsx">
      <Box sx={{
      height: '100%',
      bgcolor: 'background.default',
      color: 'text.primary'
    }} data-sentry-element="Box" data-sentry-source-file="Custom.tsx">
        {children}
      </Box>
    </ThemeProvider>;
};
export default CustomThemeProvider;