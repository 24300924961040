import '../theme/scss/globals.scss';
import { GeistSans } from 'geist/font/sans';
import appWithI18n from 'next-translate/appWithI18n';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import ContextProvider from '@/context';
import Analytics from '@/core/analytics';
import useEventTracker from '@/core/analytics/useEventTracker';
import LayoutBuilder from '@/layouts/Builder';
import ErrorBoundary from '@/layouts/ErrorBoundary';
import ThemeProvider from '@/theme/ThemeProvider/Default';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import i18nConfig from '../../i18n';
interface MyAppProps extends AppProps {
  deviceType: string;
}
const MyApp = (props: MyAppProps) => {
  const {
    Component,
    pageProps,
    router
  } = props;
  const eventTracker = useEventTracker();
  useEffect(() => {
    eventTracker.system.appLoaded();
  }, []);
  return <AppCacheProvider {...props} data-sentry-element="AppCacheProvider" data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <meta name="viewport" content="user-scalable=no, initial-scale=1, minimum-scale=1, maximum-scale=1, width=device-width height=device-height, shrink-to-fit=no, viewport-fit=cover" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
      </Head>
      <main className={GeistSans.variable}>
        <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="_app.tsx">
          <ThemeProvider deviceType={pageProps.deviceType} data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
            <LocalizationProvider dateAdapter={AdapterMoment} data-sentry-element="LocalizationProvider" data-sentry-source-file="_app.tsx">
              <Analytics data-sentry-element="Analytics" data-sentry-source-file="_app.tsx" />
              <SnackbarProvider maxSnack={3} anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }} data-sentry-element="SnackbarProvider" data-sentry-source-file="_app.tsx">
                <ContextProvider appProps={{
                ...pageProps,
                router
              }} data-sentry-element="ContextProvider" data-sentry-source-file="_app.tsx">
                  <LayoutBuilder pageProps={pageProps} data-sentry-element="LayoutBuilder" data-sentry-source-file="_app.tsx">
                    <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                  </LayoutBuilder>
                </ContextProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </main>
    </AppCacheProvider>;
};

// @ts-ignore
export default appWithI18n(MyApp, {
  ...i18nConfig,
  // Set to false if you want to load all the namespaces on _app.js getInitialProps
  skipInitialProps: false,
  loader: false
});