

// import { useAuthEvents } from '@/events';
// import * as Sentry from '@sentry/nextjs';

const useSentry = () => {

    // hooks
    // const authEvents = useAuthEvents();

    // authEvents.userAuth.useListener(({ isLogged, userData }) => {
    //     if (isLogged && userData) {
    //         Sentry.setUser({
    //             id: userData.id,
    //             email: userData.email,
    //             username: userData.username,
    //         });
    //     } else {
    //         Sentry.setUser(null);
    //     }
    // });

};

export default useSentry;
