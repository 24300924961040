// ** MUI Imports
import { Theme } from '@mui/material/styles';

const Container = (theme: Theme) => {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down('xl')]: {
            padding: theme.spacing(0, 10),
          },
          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 5),
          },
        }
      }
    }
  }
}

export default Container
