
import { useEffect, useState } from 'react';


const initialState = {
    managers: [],
    targets: [],
    accounts: [],
    balance: 0,
    credit_cost: {},
    usd_to_points: 0,
};

export const useDataContext = () => {

    // states
    const [contextData, setContentData] = useState(initialState);

    useEffect(() => {
        loadContext();
    }, []);

    const loadContext = async () => {
    };

    return {
        contextData,
    };
};