import { event as gaEvent } from 'nextjs-google-analytics';
import { useCallback } from 'react';

import { logger } from '@/utils/logger';

import createEvent from './eventsList';
import useMixpanel from './useMixpanel';

import type { Category } from './eventsList';

export interface EventParams {
  eventType: 'page-view' | 'event' | 'identify' | 'set';
  category: Category;
  action: string;
  label?: string;
  value?: number;
  additionalData?: Record<string, any>;
}

const useEventTracker = () => {

  // hooks
  const { trackEvent, trackPageView, identifyUser, setUserProperties } = useMixpanel();

  // Identify user and set user properties in Mixpanel when logged in
  // authEvents.userAuth.useListener(({ isLogged, userData }) => {
  //   if (isLogged && userData) {
  //     identifyUser(userData.id);
  //     setUserProperties({
  //       $email: userData.email,
  //       $name: userData.username,
  //     });
  //   } else {
  //     setUserProperties({
  //       $email: '',
  //       $name: '',
  //     });
  //   }
  // });

  const event = useCallback(({ eventType, category, action, label = '', value = 0, additionalData = {} }: EventParams) => {
    if (typeof window === 'undefined') return;

    const eventName = `@${category}:${action}${label ? `:${label}` : ''}`;
    const properties = { category, label, value, ...additionalData };

    logger.info(`Tracking Event: ${eventName}`, properties);

    // Google Analytics event tracking
    gaEvent(eventName, {
      category,
      label,
      value,
      ...additionalData,
    });

    // Mixpanel event tracking
    switch (eventType) {
      case 'page-view':
        trackPageView(properties);
        break;
      case 'event':
        trackEvent(eventName, properties);
        break;
      case 'identify':
        if (label) identifyUser(label);
        break;
      case 'set':
        setUserProperties(properties);
        break;
      default:
        logger.warn(`Unhandled eventType: ${eventType}`);
    }
  }, [trackEvent, trackPageView, identifyUser, setUserProperties]);

  // Return events list
  return createEvent(event);
};

export default useEventTracker;
