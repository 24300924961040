import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import { useEvent } from 'react-use';

import { logger } from '@/utils/logger';

export const useBase = <T = void>(eventName?: string) => {
    // Generate an event name based on the provided name or fallback to a unique identifier
    const generatedEventName = eventName || `event_${nanoid()}`;

    // Emit function for the event
    const emit = useCallback((detail?: T) => {
        requestAnimationFrame(() => {
            logger.debug('Emitting event:', generatedEventName, detail);
            return window.dispatchEvent(new CustomEvent(generatedEventName, { detail }));
        });
    }, [generatedEventName]);

    // Listener hook using `useEvent` from `react-use`
    const useListener = (handler: (detail: T) => void) => {
        logger.debug('Listening for event:', generatedEventName);
        useEvent(generatedEventName, (e: CustomEvent<T>) => handler(e.detail));
    };

    return {
        emit,
        useListener,
    };
};
