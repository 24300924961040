
import aiDataScience from './detail/ai-data-science/data';
import aiSolutions from './detail/ai-solutions/data';
import botsAutomation from './detail/bots-automation/data';
import browserExtensions from './detail/browser-extensions/data';
import customSoftwareDevelopment from './detail/custom-software-development/data';
import customSoftware from './detail/custom-software/data';
import desktopApps from './detail/desktop-apps/data';
import iGamingCasions from './detail/igaming-casinos/data';
import iGamingGames from './detail/igaming-games/data';
import interactive3D from './detail/interactive-3d/data';
import mobileAppDevelopment from './detail/mobile-app-development/data';
import mobileApps from './detail/mobile-apps/data';
import nftsMarketplaces from './detail/nfts-marketplaces/data';
import parserScraper from './detail/parser-scraper/data';
import paymentGateways from './detail/payment-gateways/data';
import platformInfrastructure from './detail/platform-infrastructure/data';
import qaTesting from './detail/qa-testing/data';
import uxUiDesign from './detail/ux-ui-design/data';
import webAppsPwas from './detail/web-apps-pwas/data';

import type { PageData } from './types';

const data: PageData[] = [
    customSoftwareDevelopment,
    qaTesting,
    aiDataScience,
    mobileAppDevelopment,
    uxUiDesign,
    platformInfrastructure,
    parserScraper,
    botsAutomation,
    iGamingGames,
    iGamingCasions,
    aiSolutions,
    interactive3D,
    paymentGateways,
    nftsMarketplaces,
    browserExtensions,
    desktopApps,
    mobileApps,
    webAppsPwas,
    customSoftware,
]

export const getPageData = (slug: string): PageData => {
    for (const projectData of data) {
        if (projectData.slug === slug) {
            const filePath = `src/views/solutions/detail/${slug}/${projectData.fileName}`;
            return {
                ...projectData,
                filePath,
            };
        }
    }

    return {
        icon: '',
        slug: 'unknown',
        title: 'Unknown',
        description: 'Unknown',
        fileName: '',
    };
}

export default data;