// ** MUI Imports
import { Theme } from '@mui/material/styles';

// ** Theme Type Import
import { Skin } from '../types';

const Dialog = (theme: Theme, skin: Skin) => {
  const isDark = theme.palette.mode === 'dark'

  return {
    MuiDialog: {
      styleOverrides: {
        // root: {
        //   backdropFilter: 'blur(10px)'
        // },
        paper: {
          borderRadius: theme.shape.borderRadius * 2,
          overflow: 'hidden',
          ...(isDark && {
            border: `1px solid ${theme.palette.divider}`,
          }),
          '&.MuiDialog-paperFullScreen': {
            borderRadius: 0,
            border: 0,
          },
        },
      }
    },
    // MuiDialogTitle: {
    //   styleOverrides: {
    //     root: {
    //       padding: theme.spacing(5),
    //     },
    //   }
    // },
    // MuiDialogContent: {
    //   styleOverrides: {
    //     root: {
    //       '& + .MuiDialogContent-root': {
    //         paddingTop: 0
    //       },
    //       '& + .MuiDialogActions-root': {
    //         paddingTop: 0
    //       }
    //     }
    //   }
    // },
    // MuiDialogActions: {
    //   styleOverrides: {
    //     root: {
    //       '&.dialog-actions-dense': {
    //         padding: theme.spacing(2.5),
    //       }
    //     }
    //   }
    // }
  }
}

export default Dialog
