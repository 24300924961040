import { useRouter } from 'next/router';
import { memo } from 'react';
import { useNetworkState } from 'react-use';
import { useAuthContext } from '@/context';
import routes from '@/core/routes';
// snippets
import ToolbarMenu from '@/snippets/ToolbarMenu';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Typography } from '@mui/material';
import Switchers from './Switchers';
const UserMenu = () => {
  // context
  const authContext = useAuthContext();

  // hooks
  const router = useRouter();
  const network = useNetworkState();

  // icons
  // const ConnectionIcon = network.online ? CloudOutlinedIcon : CloudOffOutlinedIcon;

  const menuItems = [{
    label: 'Settings',
    icon: SettingsOutlinedIcon,
    onClick: () => {
      // commonEvents.settingsDialog.emit();
    }
  },
  // {
  //     label: 'Support Center',
  //     icon: HelpOutlineOutlinedIcon,
  //     onClick: () => {
  //         // events.common.supportCenter.emit();
  //     },
  // },
  {
    label: 'Report an issue',
    icon: BugReportOutlinedIcon,
    onClick: () => {
      const url = routes.pages.contact.path;
      router.push(url);
    }
  }, {
    label: 'Logout',
    icon: LogoutOutlinedIcon,
    onClick: async () => {
      const confirmed = await window.common.confirm({
        title: 'Logout',
        message: 'Are you sure you want to logout?',
        confirmButton: {
          color: 'error',
          label: 'Logout'
        }
      });
      if (confirmed) {
        authContext.manager.logout();
      }
    }
  }];
  return <div data-sentry-component="UserMenu" data-sentry-source-file="index.tsx">
            <ToolbarMenu size='small' icon={() => {
      return <AccountCircleOutlinedIcon sx={{
        fontSize: 24
      }} />;
    }} menuItems={menuItems} position="right" tooltipLabel="User Menu" sx={{
      color: 'text.primary',
      p: 2
    }} data-sentry-element="ToolbarMenu" data-sentry-source-file="index.tsx">
                <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                    <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2
        }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                        <Typography variant="subtitle2" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
                            Network
                        </Typography>
                        <Typography variant="subtitle2" sx={{
            color: network.online ? 'success.main' : 'error.main'
          }} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
                            {network.online ? "Online" : "Offline"}
                        </Typography>
                    </Box>

                    <Switchers data-sentry-element="Switchers" data-sentry-source-file="index.tsx" />
                </Box>

            </ToolbarMenu>
        </div>;
};
export default memo(UserMenu);