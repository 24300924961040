
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'fluent:tab-desktop-multiple-20-regular',
    slug: 'desktop-apps',
    title: "Desktop App Development | Cross-Platform Solutions by Unrealos",
    description: "Unrealos specializes in cross-platform desktop app development for Windows, macOS, and Linux. Tailored solutions built with Electron for versatile and user-friendly applications.",
    fileName: 'content.mdx',
    Component,
}

export default data;