
import type { PageData } from '../../types';
import Component from './content.mdx';

const data: PageData = {
    icon: 'icon-park-outline:graphic-design',
    slug: 'ux-ui-design',
    title: "UX/UI Design Services | Pixel-Perfect and User-Centric Experiences by Unrealos",
    description: "Unrealos creates stunning, user-friendly UX/UI designs that captivate users and drive results. Specializing in responsive, accessible, and high-performance digital experiences.",
    fileName: 'content.mdx',
    Component,
}

export default data;