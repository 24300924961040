import Wave from 'react-wavify';
import Wrapper from '@/components/MdxViewer';
import RoundBox from '@/snippets/RoundBox';
import Press from '@/views/about/Press';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import MainContent from './content/Main.mdx';
import Header from './Header';
import Partners from './Partners';
import Services from './Services';
import Technologies from './Technologies';
export default function Home() {
  const theme = useTheme();
  return <Box sx={{
    position: 'relative'
  }} data-sentry-element="Box" data-sentry-component="Home" data-sentry-source-file="index.tsx">
            <RoundBox variant="top" darken={.1} zIndex={1} bgcolor='background' data-sentry-element="RoundBox" data-sentry-source-file="index.tsx">
                <Header data-sentry-element="Header" data-sentry-source-file="index.tsx" />
            </RoundBox>
            <Box sx={{
      position: 'relative'
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                <Wave fill={theme.palette.background.paper} paused={false} style={{
        pointerEvents: 'none',
        position: 'absolute',
        top: -130,
        left: 0,
        width: '100%',
        zIndex: 2
      }} options={{
        height: 110,
        amplitude: 20,
        speed: 0.15,
        points: 10
      }} data-sentry-element="Wave" data-sentry-source-file="index.tsx" />
                <RoundBox variant="bottom" bgcolor='background' darken={.2} sx={{
        mt: -3,
        minHeight: 100
      }} data-sentry-element="RoundBox" data-sentry-source-file="index.tsx">
                    <Partners data-sentry-element="Partners" data-sentry-source-file="index.tsx" />
                </RoundBox>
            </Box>

            <Box pt={10} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                <Box sx={{
        py: {
          xs: 10,
          md: 20
        }
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                    <Container maxWidth="lg" data-sentry-element="Container" data-sentry-source-file="index.tsx">
                        <Wrapper Component={MainContent} data-sentry-element="Wrapper" data-sentry-source-file="index.tsx" />
                    </Container>
                </Box>

                <RoundBox variant="both" bgcolor='background' shift darken={.4} sx={{
        py: {
          xs: 10,
          md: 20
        }
      }} data-sentry-element="RoundBox" data-sentry-source-file="index.tsx">
                    <Container maxWidth="xl" data-sentry-element="Container" data-sentry-source-file="index.tsx">
                        <Technologies data-sentry-element="Technologies" data-sentry-source-file="index.tsx" />
                    </Container>
                </RoundBox>
            </Box>

            <Box sx={{
      py: {
        xs: 10,
        md: 20
      }
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
                <Container maxWidth="xl" data-sentry-element="Container" data-sentry-source-file="index.tsx">
                    <Services data-sentry-element="Services" data-sentry-source-file="index.tsx" />
                </Container>
            </Box>

            <RoundBox variant="both" bgcolor='background' darken={.2} sx={{
      py: {
        xs: 10,
        md: 20
      }
    }} data-sentry-element="RoundBox" data-sentry-source-file="index.tsx">
                <Container maxWidth="lg" data-sentry-element="Container" data-sentry-source-file="index.tsx">
                    <Press data-sentry-element="Press" data-sentry-source-file="index.tsx" />
                </Container>
            </RoundBox>

        </Box>;
}