import { createContext, ReactNode, useContext, useEffect } from 'react';

// createContextBuilder.ts
import { logger } from '@/utils/logger';
interface ProviderProps {
  hydrate?: boolean;
  children: ReactNode;
  [key: string]: any; // so we can pass arbitrary props, including initialValues
}
export function createContextBuilder<CustomContextType, PropsType = {}>(hook: (props: PropsType) => CustomContextType) {
  const Context = createContext<CustomContextType | undefined>(undefined);
  const useContextHook = (): CustomContextType => {
    const context = useContext(Context);
    if (!context) {
      throw new Error(`${hook.name} must be used within a Provider`);
    }
    return context;
  };
  const Provider = ({
    children,
    hydrate,
    ...props
  }: ProviderProps) => {
    const contextValue = hook(props as PropsType);

    // For shallow comparison of props
    const propsString = props ? JSON.stringify(props) : null;
    useEffect(() => {
      // If there's no propsString or hydrate is false, do nothing
      if (!propsString || !hydrate) return;

      // If the contextValue has a `hydrate` method, call it
      if (typeof (contextValue as any)?.hydrate === 'function') {
        (contextValue as any).hydrate(props);
        logger.debug(`${hook.name} hydrated`);
      } else {
        logger.warn(`${hook.name} does not have a hydrate function`);
      }
    }, [hydrate, propsString]);
    return <Context.Provider value={contextValue} data-sentry-element="unknown" data-sentry-component="Provider" data-sentry-source-file="builder.tsx">{children}</Context.Provider>;
  };
  return {
    Provider,
    useContextHook
  };
}