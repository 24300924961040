import { PaletteMode } from '@mui/material';
import { grey } from '@mui/material/colors';
import { alpha, darken, lighten } from '@mui/material/styles';

// types
import type { CustomColors } from '../types';

export const themeColors = {
  themes: {
    light: {
      paper: '#ffffff',
      default: '#EEEEEE',
      divider: 'rgba(0,0,0,.08)',
      overlay1: 'rgba(0,0,0,.06)',
      overlay2: '#E0E0E0',
      overlay3: 'rgba(255,255,255,.35)',
    },
    dark: {
      paper: '#1E1E1E',
      default: '#262626',
      divider: 'rgba(255,255,255,.08)',
      overlay1: 'rgba(255,255,255,.06)',
      overlay2: '#363636',
      overlay3: 'rgba(0,0,0,.15)',
    }
  },
  getPrimary: (theme: PaletteMode) => {
    const main = '#004DE5';
    const res = {
      light: lighten(main, .1),
      main,
      dark: darken(main, .1),
      contrastText: '#FFF'
    };
    if (theme === 'dark') {
      res.light = lighten(main, .5);
      res.main = lighten(main, .3);
      res.dark = lighten(main, .2);
    }
    return res;
  },
  // secondaryLight: darken('#ffffff', .1),
  // secondaryDark: lighten('#252534', .1),
  error: {
    light: '#ED7084',
    main: '#F2184F',
    dark: '#CC043C',
    contrastText: '#FFF'
  },
  warning: {
    light: '#FFCA64',
    main: '#FFB400',
    dark: '#E09E00',
    contrastText: '#FFF'
  },
  info: {
    light: '#32BAFF',
    main: '#16B1FF',
    dark: '#139CE0',
    contrastText: '#FFF'
  },
  success: {
    light: '#59BC70',
    main: '#479A5A',
    dark: '#3D854F',
    contrastText: '#FFF'
  },
};


class Palette {
  mode: PaletteMode;
  isLight: boolean;
  isDark: boolean

  mainColor: string;
  textPrimary: string;
  textSecondary: string;

  constructor(mode: PaletteMode) {
    this.mode = mode;
    this.isLight = mode === 'light';
    this.isDark = mode === 'dark';

    this.mainColor = this.setForMode('31, 29, 51', '231, 227, 252');
    this.textPrimary = this.rgba(this.mainColor, 0.97);
    this.textSecondary = this.rgba(this.mainColor, 0.6);
  }

  private rgba(color: string, opacity: number): string {
    return `rgba(${color}, ${opacity})`;
  }

  private get theme() {
    const theme = themeColors.themes[this.mode];
    return theme ? theme : themeColors.themes.light;
  }

  private setForMode(light: string, dark: string = light) {
    return this.isLight ? light : dark
  }

  private getSecondaryColor() {
    const color = this.setForMode('#252534', '#ffffff');

    return {
      light: alpha(color, .08),
      main: alpha(color, .15),
      dark: alpha(color, .20),
      contrastText: this.textPrimary,
      // alpha: (opacity: number) => alpha(color, opacity)
    };
  }

  private get customColors(): CustomColors {
    return {
      gradient: {
        primary: '#BD14CC',
        secondary: '#004CE5',
        horizontal: 'linear-gradient(135deg, #BD14CC, #004CE5)',
      },
      overlay1: this.theme.overlay1,
      overlay2: this.theme.overlay2,
      overlay3: this.theme.overlay3,
      divider: this.theme.divider,
      linkColor: themeColors.getPrimary(this.mode).main,

      // function getting object for light and dark mode and use with applyStyles
      // apply: (light: object, dark: object) => this.isLight ? light : dark,
    };
  }

  public generatePalette() {
    return {
      customColors: this.customColors,
      mode: this.mode,
      common: {
        black: '#000',
        white: '#fff',
      },
      primary: themeColors.getPrimary(this.mode),
      secondary: this.getSecondaryColor(),
      error: themeColors.error,
      warning: themeColors.warning,
      info: themeColors.info,
      success: themeColors.success,
      grey,
      text: {
        primary: this.textPrimary,
        secondary: this.textSecondary,
      },
      divider: this.rgba(this.mainColor, 0.12),
      background: {
        paper: this.theme.paper,
        default: this.theme.default,
      },
      action: {
        active: this.rgba(this.mainColor, 0.54),
        hover: this.rgba(this.mainColor, 0.04),
        selected: this.rgba(this.mainColor, 0.06),
        disabled: this.rgba(this.mainColor, 0.26),
        disabledBackground: this.rgba(this.mainColor, 0.12),
        focus: this.rgba(this.mainColor, 0.12),
      }
    };
  }
}

export default Palette;
