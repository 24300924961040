import React, { useState } from 'react';
import { useDataContextContext } from '@/layouts/CabinetLayout/context';
import { Box, Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
export default function BillingMenu() {
  // context
  const {
    contextData
  } = useDataContextContext();

  // states
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return <>
            <Button onClick={handleClick} variant="outlined" size="small" color="inherit" disableElevation data-sentry-element="Button" data-sentry-source-file="BillingMenu.tsx">
                {contextData.balance} credits
            </Button>
            <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }} transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }} slotProps={{
      paper: {
        sx: {
          width: 230,
          p: 5
        }
      }
    }} data-sentry-element="Popover" data-sentry-source-file="BillingMenu.tsx">
                <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }} data-sentry-element="Box" data-sentry-source-file="BillingMenu.tsx">
                    <Typography variant='h6' data-sentry-element="Typography" data-sentry-source-file="BillingMenu.tsx">Balance</Typography>
                    <Typography variant='body1' data-sentry-element="Typography" data-sentry-source-file="BillingMenu.tsx">{contextData.balance} credits</Typography>
                    <Divider data-sentry-element="Divider" data-sentry-source-file="BillingMenu.tsx" />
                    <Button variant="contained" color="success" onClick={() => {
          // commonEvents.paymentDialog.emit();
          handleClose();
        }} data-sentry-element="Button" data-sentry-source-file="BillingMenu.tsx">
                        Add funds
                    </Button>
                </Box>
            </Popover>
        </>;
}