// ** MUI Imports
import { alpha, Theme } from '@mui/material/styles';

// ** Theme Type Import
import { Skin } from '../types';

const Popover = (theme: Theme, skin: Skin) => {
  return {
    MuiPopover: {
      styleOverrides: {
        root: {
          '& .MuiPopover-paper': {
            borderRadius: theme.shape.borderRadius,

            boxShadow: theme.shadows[2],
            ...(theme.palette.mode === 'dark' && {
              border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
              boxShadow: theme.shadows[7],
            }),
            // ...(skin === 'bordered' && { border: `1px solid ${theme.palette.divider}` })
          }
        }
      }
    }
  }
}

export default Popover
