import 'moment/locale/ru';

import { getCookie, setCookie } from 'cookies-next';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import i18nConfig from '../../../i18n';

export const useI18n = () => {
    const router = useRouter();

    useEffect(() => {
        init();
    }, [router.isReady]);

    // Set moment.js locale based on the current locale
    useEffect(() => {
        if (!router.isReady) return;
        moment.locale(router.locale);
    }, [router.isReady, router.locale]);

    const init = async () => {
        if (!router.isReady) return;

        const userLang = await getCookie('user_lang'); // Get the user's preferred language from the cookie

        const browserLang = navigator.language.split('-')[0]; // Extract browser locale
        const currentUrlLocale = router.locale; // Locale explicitly in the URL

        // If the locale is specified in the URL and valid, update the cookie to match (if it's not a default locale)
        if (currentUrlLocale && i18nConfig.locales.includes(currentUrlLocale) && currentUrlLocale !== i18nConfig.defaultLocale) {
            if (userLang !== currentUrlLocale) {
                setCookie('user_lang', currentUrlLocale); // Update the cookie to match the URL locale
            }
        }
        // If no userLang cookie exists, and the browserLang is valid, set the cookie and redirect
        else if (!userLang && i18nConfig.locales.includes(browserLang)) {
            setCookie('user_lang', browserLang);
            router.push(router.asPath, router.asPath, { locale: browserLang });
        }
        // If userLang exists and differs from the current locale, redirect (only if it's not URL-driven)
        else if (userLang && userLang !== currentUrlLocale && i18nConfig.locales.includes(userLang)) {
            router.push(router.asPath, router.asPath, { locale: userLang });
        }
    }
};
