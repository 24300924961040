import { ErrorInfo } from 'react';
import { Link } from '@/components/Link';
import routes from '@/core/routes';
import { useCopy } from '@/hooks/useCopy';
import LogoAnimation from '@/snippets/LogoAnimation';
import ThemeDefault from '@/theme/ThemeProvider/Default';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Container, TextField, Tooltip, Typography } from '@mui/material';
interface Props {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  onReset: () => void;
}
const ErrorFallback: React.FC<Props> = ({
  error,
  errorInfo,
  onReset
}) => {
  // hooks
  const copy = useCopy();
  const handleCopyError = () => {
    copy.copy(JSON.stringify({
      error,
      errorInfo
    }, null, 2));
  };
  return <Box sx={{
    height: '100vh',
    py: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }} data-sentry-element="Box" data-sentry-component="ErrorFallback" data-sentry-source-file="ErrorFallback.tsx">
            <Container maxWidth="md" data-sentry-element="Container" data-sentry-source-file="ErrorFallback.tsx">
                <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        textAlign: 'center'
      }} data-sentry-element="Box" data-sentry-source-file="ErrorFallback.tsx">
                    <LogoAnimation size={100} followCursor data-sentry-element="LogoAnimation" data-sentry-source-file="ErrorFallback.tsx" />
                    <Box data-sentry-element="Box" data-sentry-source-file="ErrorFallback.tsx">
                        <Typography variant="h2" align="center" gutterBottom data-sentry-element="Typography" data-sentry-source-file="ErrorFallback.tsx">
                            Something went wrong
                        </Typography>
                        <Typography variant="body1" align="center" data-sentry-element="Typography" data-sentry-source-file="ErrorFallback.tsx">
                            Please try again or contact support.
                        </Typography>
                    </Box>

                    <Box sx={{
          display: 'flex',
          gap: 2
        }} data-sentry-element="Box" data-sentry-source-file="ErrorFallback.tsx">
                        <Button component="a" href="/" variant="contained" color="primary" data-sentry-element="Button" data-sentry-source-file="ErrorFallback.tsx">
                            Go to Home
                        </Button>
                        <Button startIcon={<RefreshIcon />} variant="outlined" color="inherit" onClick={() => window.location.reload()} data-sentry-element="Button" data-sentry-source-file="ErrorFallback.tsx">
                            Reload
                        </Button>
                    </Box>

                    <Box data-sentry-element="Box" data-sentry-source-file="ErrorFallback.tsx">
                        <Link href={routes.pages.contact.path} align="center" variant="body2" target="_blank" data-sentry-element="Link" data-sentry-source-file="ErrorFallback.tsx">
                            Contact Support
                        </Link>
                    </Box>

                    <Box sx={{
          width: '100%',
          position: 'relative'
        }} data-sentry-element="Box" data-sentry-source-file="ErrorFallback.tsx">
                        <Box sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            p: 5,
            zIndex: 1
          }} data-sentry-element="Box" data-sentry-source-file="ErrorFallback.tsx">
                            <Tooltip title={copy.copied ? 'Error data copied!' : 'Copy error details'} data-sentry-element="Tooltip" data-sentry-source-file="ErrorFallback.tsx">
                                <Button variant="contained" color="primary" size="small" disabled={copy.copied} onClick={handleCopyError} data-sentry-element="Button" data-sentry-source-file="ErrorFallback.tsx">
                                    {copy.copied ? 'Copied' : 'Copy'}
                                </Button>
                            </Tooltip>
                        </Box>

                        <TextField value={JSON.stringify({
            error,
            errorInfo
          }, null, 2)} fullWidth multiline rows={5} inputProps={{
            readOnly: true
          }} sx={{
            '.MuiInputBase-root': {
              bgcolor: 'background.paper'
            },
            '& .MuiInputBase-input': {
              fontFamily: 'monospace',
              whiteSpace: 'pre',
              fontSize: '0.75rem'
            }
          }} data-sentry-element="TextField" data-sentry-source-file="ErrorFallback.tsx" />
                    </Box>
                </Box>
            </Container>
        </Box>;
};
const ErrorBoundary = (props: Props) => <ThemeDefault data-sentry-element="ThemeDefault" data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorFallback.tsx">
        <ErrorFallback {...props} data-sentry-element="ErrorFallback" data-sentry-source-file="ErrorFallback.tsx" />
    </ThemeDefault>;
export default ErrorBoundary;