import React from 'react';

// mui
import { Box, Switch, Typography } from '@mui/material';
interface SwitcherProps {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  valueLabel?: string; // Optional parameter to display theme name instead of On/Off
}
const SwitcherWrapper: React.FC<SwitcherProps> = ({
  label,
  checked,
  onChange,
  valueLabel // Display custom label (e.g., theme) instead of On/Off
}) => {
  return <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  }} data-sentry-element="Box" data-sentry-component="SwitcherWrapper" data-sentry-source-file="SwitcherWrapper.tsx">
            <Typography variant="subtitle2" data-sentry-element="Typography" data-sentry-source-file="SwitcherWrapper.tsx">
                {label}
                <Box component="span" sx={{
        ml: 1,
        color: 'text.secondary'
      }} data-sentry-element="Box" data-sentry-source-file="SwitcherWrapper.tsx">
                    ({valueLabel ? valueLabel : checked ? 'On' : 'Off'})
                </Box>
            </Typography>

            <Switch checked={checked} onChange={onChange} color="default" size="small" sx={{
      mr: -2
    }} data-sentry-element="Switch" data-sentry-source-file="SwitcherWrapper.tsx" />
        </Box>;
};
export default SwitcherWrapper;