// ** MUI Imports
import { Theme } from '@mui/material/styles';

// ** Theme Type Import
import { Skin } from '../types';

const Popper = (theme: Theme, skin: Skin) => {
  return {
    MuiPopper: {
      styleOverrides: {
        root: {
          // ...
        },
      }
    }
  }
}

export default Popper
