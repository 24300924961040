import { useRouter } from 'next/router';
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, ButtonProps, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Slide, { SlideProps } from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

// Slide Transition Component
const Transition = forwardRef(function Transition(props: TransitionProps & {
  children: React.ReactElement<any, any>;
}, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export interface UniversalDialogProps {
  open?: boolean; // Optional because it will be controlled internally
  dialog?: Omit<DialogProps, 'open'>; // DialogProps, but without 'open'
  fullScreen?: boolean;
  title?: string;
  loading?: boolean;
  showClose?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  minWidth?: string;
  close?: boolean;
  preventClose?: boolean;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  onClose?: () => void; // Optional onClose handler
  onOpen?: () => void; // Optional onOpen handler
  transition?: SlideProps['direction']; // Slide transition direction
  scroll?: 'body' | 'paper'; // Scroll type for dialog
  dividers?: boolean; // Optional dividers for dialog content
  dialogActions?: ButtonProps[]; // Array of ButtonProps for dialog actions
  keepMounted?: boolean;
}
export interface UniversalDialogRef {
  openDialog: () => void;
  closeDialog: () => void;
  dispatchSettings: (settings: Partial<Settings>) => void;
}
type Settings = {
  preventClose: boolean;
  loading: boolean;
};
const UniversalDialog = forwardRef<UniversalDialogRef, UniversalDialogProps>(({
  open: controlledOpen,
  dialog,
  fullScreen = false,
  title,
  showClose = true,
  maxWidth,
  minWidth,
  close = true,
  sx,
  children,
  onClose,
  onOpen,
  transition,
  scroll = 'paper',
  dividers = true,
  dialogActions = [],
  keepMounted = false,
  ...props
}, ref) => {
  const theme = useTheme();
  const router = useRouter();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // State for controlling the dialog open state internally
  const [open, setOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [settings, setSettings] = useState<Settings>({
    preventClose: props.preventClose || false,
    loading: props.loading || false
  });
  const dispatchSettings = (settings: Partial<Settings>) => {
    setSettings(prev => ({
      ...prev,
      ...settings
    }));
  };

  // Handle the opening and closing internally or via props
  const handleOpen = useCallback(() => {
    onOpen?.();
    setOpen(true);
  }, [onOpen]);
  const handleClose = useCallback(() => {
    if (settings.preventClose) {
      return;
    }
    onClose?.();
    setOpen(false);
  }, [close, onClose]);

  // Use the external controlled open state if provided, otherwise internal state
  const isDialogOpen = controlledOpen !== undefined ? controlledOpen : open;

  // Imperative handle for external components to trigger open and close actions
  useImperativeHandle(ref, (): UniversalDialogRef => ({
    openDialog: handleOpen,
    closeDialog: handleClose,
    dispatchSettings: dispatchSettings
  }));

  /**
   * Close dialog on route change
   */
  useEffect(() => {
    const handleRouteChange = () => {
      handleClose();
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [handleClose, router.events]);

  /**
   * Update state
   */
  useEffect(() => {
    setSettings(prev => ({
      ...prev,
      loading: props.loading || false
    }));
  }, [props.loading]);
  const handleEnter = () => {
    setIsMounted(true);
  };
  const handleExited = () => {
    setIsMounted(false);
  };

  // Memoize children to prevent unnecessary re-renders
  const memoizedChildren = useMemo(() => children, [children]);
  const setFullscreen = isFullScreen && fullScreen === true;
  return <Dialog open={isDialogOpen} // Use the controlled or internal state
  onClose={(e, reason) => {
    if (settings.preventClose) {
      return;
    }
    if (!close) {
      if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
        return;
      }
    }
    handleClose();
  }} fullScreen={setFullscreen} maxWidth={maxWidth} TransitionComponent={transition && Transition} TransitionProps={{
    onEnter: handleEnter,
    onExited: handleExited
  }} scroll={scroll} fullWidth keepMounted={keepMounted} sx={{
    // dialog width
    '& .MuiDialog-paper': {
      minWidth: minWidth || 'auto'
    }
  }} {...props}>
        {settings.loading ? <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 200
    }}>
            <CircularProgress size={20} />
          </Box> : <>
            {title && <DialogTitle>
                {title}
                {showClose && !settings.preventClose && <IconButton aria-label="close" onClick={handleClose} sx={{
          position: 'absolute',
          right: 8,
          top: 8
        }}>
                    <CloseIcon />
                  </IconButton>}
              </DialogTitle>}
            <DialogContent dividers={dividers} sx={{
        ...sx,
        ...(dialogActions.length === 0 && {
          borderBottom: 0
        }),
        '&.MuiDialogContent-root': {
          overflowY: 'auto !important',
          ...(!setFullscreen && {
            maxHeight: '80vh'
          })
        }
      }}>
              {keepMounted ? memoizedChildren : isMounted && memoizedChildren}
            </DialogContent>
            {dialogActions.length > 0 && <DialogActions>
                {dialogActions.map((actionProps, index) => <Button key={index} {...actionProps} />)}
              </DialogActions>}
          </>}
      </Dialog>;
});
export default memo(UniversalDialog);