import { useRef } from 'react';
import { useTheme } from '@mui/material/styles';

// hooks
import { useFollowCursor } from './useFollowCursor';
import { useWaveAnimation } from './useWaveAnimation';
interface Props {
  size: number;
  followCursor?: boolean;
}

// Main component
const LogoAnimation = ({
  size,
  followCursor = false
}: Props) => {
  const theme = useTheme();
  const svgRef = useRef<SVGSVGElement>(null);
  const pathRef = useRef<SVGPathElement>(null);
  const leftPupilRef = useRef<SVGCircleElement>(null);
  const rightPupilRef = useRef<SVGCircleElement>(null);

  // Use hooks for animations
  useWaveAnimation({
    pathRef
  });
  useFollowCursor({
    svgRef,
    leftPupilRef,
    rightPupilRef,
    followCursor
  });
  return <svg ref={svgRef} width={size} height={size} viewBox="0 -5 91 100" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="LogoAnimation" data-sentry-source-file="index.tsx">
            <path ref={pathRef} fillRule="evenodd" clipRule="evenodd" d="M0 45.5386C0 20.4723 20.4723 0 45.5413 0C70.5274 0 91.0008 20.4723 91.0008 45.5386V82.4567C91.0008 88.1796 87.0496 90.354 83.0203 85.2779C79.1522 80.4406 76.2503 73.9124 73.9124 73.9124C70.1247 73.9124 67.3034 91.001 59.7255 91.001C52.1504 91.001 49.3292 73.9124 45.5415 73.9124C41.6705 73.9124 38.8506 91.001 31.2742 91.001C23.6979 91.001 20.8765 73.9124 17.0875 73.9124C14.7508 73.9124 11.8489 80.4406 7.97946 85.2779C3.94876 90.3538 0 88.1796 0 82.4567V45.5386Z" fill="url(#paint0_linear_7709_680)" data-sentry-element="path" data-sentry-source-file="index.tsx" />
            <path fillRule="evenodd" clipRule="evenodd" d="M61.1779 31.2742C66.6593 31.2742 71.091 35.706 71.091 41.2681C71.091 46.7495 66.6591 51.1813 61.1779 51.1813C55.6158 51.1813 51.1813 46.7493 51.1813 41.2681C51.1813 35.706 55.6158 31.2742 61.1779 31.2742ZM29.9038 31.2742C24.3418 31.2742 19.9087 35.706 19.9087 41.2681C19.9087 46.7495 24.3418 51.1813 29.9038 51.1813C35.384 51.1813 39.8171 46.7493 39.8171 41.2681C39.8171 35.706 35.384 31.2742 29.9038 31.2742Z" fill="#F1F0F0" // Eye whites
    data-sentry-element="path" data-sentry-source-file="index.tsx" />
            <circle ref={leftPupilRef} cx="29.9" cy="41.3" r="3.5" fill="#0B1319" // Left pupil
    data-sentry-element="circle" data-sentry-source-file="index.tsx" />
            <circle ref={rightPupilRef} cx="61.2" cy="41.3" r="3.5" fill="#0B1319" // Right pupil
    data-sentry-element="circle" data-sentry-source-file="index.tsx" />
            <defs data-sentry-element="defs" data-sentry-source-file="index.tsx">
                <linearGradient id="paint0_linear_7709_680" x1="5.38765e-08" y1="17.7866" x2="63.0613" y2="104.496" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="index.tsx">
                    <stop stopColor={theme.palette.customColors.gradient.primary} data-sentry-element="stop" data-sentry-source-file="index.tsx" />
                    <stop offset="1" stopColor={theme.palette.customColors.gradient.secondary} data-sentry-element="stop" data-sentry-source-file="index.tsx" />
                </linearGradient>
            </defs>
        </svg>;
};
export default LogoAnimation;